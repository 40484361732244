import { FormikContextType, useFormikContext } from "formik";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";
import { FieldTypes } from "../forms/formik-components/formTypes";
import { PassportField, PassportFieldTypesDisplayMap, PassportFieldTypesToFondoFormGroupTypes } from "./passport.type";
import FormikDatePickerField from "../flatpickr/FormikFlatpickrField";
import StateField from "../forms/formik-components/StateField";

const getStateField = (
  passportFieldValue: string,
  passportFieldName: string,
  formikContext: FormikContextType<any>,
) => (
    <StateField
        fieldName={passportFieldName}
        invalid={false}
        formikContext={formikContext}
        value={passportFieldValue}
        selectedCountry="US"
    />
);

const getIncorporationDateField = (
  passportFieldValue: string,
  formikContext: FormikContextType<any>,
) => (
    <FormikDatePickerField
        fieldName="incorporation_date"
        setFieldValue={formikContext.setFieldValue}
        handleBlur={formikContext.handleBlur}
        date={passportFieldValue}
        invalid={!formikContext.isValid}
        maxDate="today"
        staticDateDisplay={false} />
);

type PassportCardEditFieldProps = {
    passportField: PassportField;
};

export default function PassportCardEditField({
  passportField: {
    isCustomField,
    fieldType,
    fieldName,
  },
}: PassportCardEditFieldProps) {
  const formikContext = useFormikContext<{ [key: string]: string }>();
  const formValue = formikContext.values[fieldName];

  let formFieldType = PassportFieldTypesToFondoFormGroupTypes[fieldType];

  if (!isCustomField) {
    if (fieldName === "subdivision") {
      return getStateField(formValue, fieldName, formikContext);
    }

    if (fieldName === "incorporation_date") {
      return getIncorporationDateField(formValue, formikContext);
    }

    if (fieldName === "ein_enc") {
      formFieldType = FieldTypes.EIN;
    }
  }

  return (
        <FondoFormGroup
            formikContext={formikContext}
            fieldName={fieldName}
            types={{ [fieldName]: formFieldType }}
            placeholders={{ [fieldName]: PassportFieldTypesDisplayMap[fieldType] }}
            enablePasswordType={true}
            staticDateDisplay={false}
            className="m-0"
        />
  );
}
