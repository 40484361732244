import { getApiClient } from "../../utils/util.api";
import { OnboardingForm, PageField } from "./dynamicOnboardingForm.type";

export const getOnboardingForm = async (slug: string): Promise<OnboardingForm> => {
  const apiClient = await getApiClient();
  const response = await apiClient.retrieveReactifyDynamicOnboardingForm(slug);
  return response.data;
};

export const getPageField = (pageUuid: string) => async (): Promise<PageField> => {
  const apiClient = await getApiClient();
  const response = await apiClient.retrieveReactifyPageFields(pageUuid);
  return response.data;
};

export const updatePageFields = async (pageUuid: string, values: any) => {
  const apiClient = await getApiClient();
  const response = await apiClient.updateReactifyPageFields(pageUuid, values);
  return response.data;
};
