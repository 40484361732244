import { useParams } from "react-router-dom";
import { FieldTypes } from "../../forms/formik-components/formTypes";

export type RndEmployee = {
  uuid?: string,
  title: string,
  state: string,
  grossEarnings: string,
  timeSpentOnRnd: string,
  employeeName: string,
};

export type RndContractor = {
  uuid?: string,
  title: string,
  state: string,
  grossEarnings: string,
  timeSpentOnRnd: string,
  contractorName: string,
};

export type RndSupplies = {
  id?: number,
  supplyName: string,
  description: string,
  cost: string,
};

export type RnDValues = {
  paidOnSupplies: string | undefined,
  paidToContractors: string | undefined,
  paidToEmployees: string | undefined,
  revenueMoreThanFiveMillion?: string,
  companyWebsite: string,
  companyDescription: string,
  createImproveProduct?: string,
  createdOrImprovedDescription: string,
  technologicalActivities?: string,
  technologicalActivitiesDescription: string,
  facedUncertainty?: string,
  uncertaintyDescription: string,
  experimentalProcesses?: string,
  experimentalProcessesDescription: string,
  rndRelatedEmployeeWages: RndEmployee[],
  rndRelatedContractorWages: RndContractor[],
  rndRelatedSupplies: RndSupplies[],
  submitted: boolean;
  taxYear: number;
};

export type RnDFormValues = RnDValues & {
  wagesToDelete: string[],
  contractorsToDelete: string[],
  suppliesToDelete: string[],
}

const fieldToLabelMap = () => {
  const { taxYear } = useParams();

  if (!taxYear) {
    return {
    paidToEmployees: "",
    paidToContractors: "",
    paidOnSupplies: "",
    revenueMoreThanFiveMillion: "",
    companyWebsite: "",
    companyDescription: "",
    createImproveProduct: "",
    createdOrImprovedDescription: "",
    technologicalActivities: "",
    technologicalActivitiesDescription: "",
    facedUncertainty: "",
    uncertaintyDescription: "",
    experimentalProcesses: "",
    experimentalProcessesDescription: "",
    };
  }

  return {
    paidToEmployees: `How much did your company pay U.S. employees in ${taxYear}?`,
    paidToContractors: `How much did your company pay U.S.-based contractors in ${taxYear}?`,
    paidOnSupplies: `How much did your company spend on R&D supplies in ${taxYear}?`,
    revenueMoreThanFiveMillion: `Did your company earn more than $5 million in revenue in ${taxYear}?`,
    companyWebsite: "Company website",
    companyDescription: "Company description",
    createImproveProduct: `Did you create or improve a product in ${taxYear}?`,
    createdOrImprovedDescription: `In a few sentences, please describe the product you worked on 
    creating or improving in ${taxYear}. If you improved the product, please explain what was improved.`,
    technologicalActivities: `Did your company perform activities "technological in nature" in ${taxYear}?`,
    technologicalActivitiesDescription: `In a few sentences, please describe the "technological in nature" 
    activities you performed in ${taxYear}.`,
    facedUncertainty: `Did your company face uncertainty at the outset of development in ${taxYear}?`,
    uncertaintyDescription: `In a few sentences, please describe the uncertainty that you faced in ${taxYear}, 
    including what specifically was uncertain and why.`,
    experimentalProcesses: `Did your company utilize experimental processes to resolve identified 
    uncertainties in ${taxYear}?`,
    experimentalProcessesDescription: `In a few sentences, please describe the experimental processes 
    you used to resolve the uncertainties in ${taxYear}.`,
  };
}

const fieldsToCheck = [
  "paidOnSupplies",
  "paidToContractors",
  "paidToEmployees",
  "revenueMoreThanFiveMillion",
  "companyWebsite",
  "companyDescription",
  "createImproveProduct",
  "technologicalActivities",
  "facedUncertainty",
  "experimentalProcesses",
];

const relatedFieldsToCheck = {
  createImproveProduct: "createdOrImprovedDescription",
  technologicalActivities: "technologicalActivitiesDescription",
  facedUncertainty: "uncertaintyDescription",
  experimentalProcesses: "experimentalProcessesDescription",
};

const wageFieldsToCheck = [
  "title",
  "state",
  "grossEarnings",
  "timeSpentOnRnd",
  "employeeName",
];

const contractorFieldsToCheck = [
  "title",
  "state",
  "grossEarnings",
  "timeSpentOnRnd",
  "contractorName",
];

const supplyFieldsToCheck = [
  "supplyName",
  "description",
  "cost",
];

const fieldToTypeMap = {
  paidToEmployees: FieldTypes.CURRENCY,
  paidToContractors: FieldTypes.CURRENCY,
  paidOnSupplies: FieldTypes.CURRENCY,
  revenueMoreThanFiveMillion: FieldTypes.YESNO,
  companyWebsite: FieldTypes.TEXT,
  companyDescription: FieldTypes.TEXTAREA,
  createImproveProduct: FieldTypes.YESNO,
  createdOrImprovedDescription: FieldTypes.TEXTAREA,
  technologicalActivities: FieldTypes.YESNO,
  technologicalActivitiesDescription: FieldTypes.TEXTAREA,
  facedUncertainty: FieldTypes.YESNO,
  uncertaintyDescription: FieldTypes.TEXTAREA,
  experimentalProcesses: FieldTypes.YESNO,
  experimentalProcessesDescription: FieldTypes.TEXTAREA,
};

const StateAbbr = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
];

export {
  contractorFieldsToCheck, fieldsToCheck, fieldToLabelMap,
  fieldToTypeMap,
  FieldTypes, relatedFieldsToCheck, StateAbbr, supplyFieldsToCheck, wageFieldsToCheck
};

