import fondoLogo from "../../assets/img/fondo-icon.png";

const FondoHeader = () => (
  <nav className="mb-4 navbar navbar-expand-lg navbar-light">
    <div className="container justify-content-center">
      <a className="navbar-brand mr-auto">
        <img src={fondoLogo} alt="Fondo" className="navbar-brand-img" />
      </a>
    </div>
  </nav>
);

export default FondoHeader;
