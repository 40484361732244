import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import Flatpickr from "./Flatpickr";

type FormikDatePickerFieldProps = {
  handleBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  setFieldValue: (field: string, value: string | undefined) => void;
  date: string | undefined;
  fieldName: string;
  minDate?: Date | string | undefined;
  maxDate?: Date | string | undefined;
  showClear?: boolean;
  invalid?: boolean;
  staticDateDisplay?: boolean;
};

export const FormikDatePickerField = ({
  handleBlur,
  setFieldValue,
  date,
  fieldName,
  minDate,
  maxDate,
  showClear,
  invalid,
  staticDateDisplay = true,
}: FormikDatePickerFieldProps) => {
  const handleDateChange = (dates: Date[]) => {
    let stringDate = "";
    if (dates.length > 0) {
      [stringDate] = dates.map((nonStringDate) => nonStringDate.toISOString());
      [stringDate] = stringDate.split("T");
    }
    setFieldValue(fieldName, stringDate);
  };

  const handleClear = () => {
    setFieldValue(fieldName, undefined);
  };

  const onBlur = () => {
    handleBlur?.({ target: { name: fieldName } } as any);
  };

  return (
    <InputGroup className="input-group-merge" size="sm">
      <Flatpickr
        value={date}
        onChange={(dates: any) => handleDateChange(dates)}
        onOpen={onBlur}
        onClose={onBlur}
        id={fieldName}
        name={fieldName}
        placeholder="MM/DD/YYYY"
        className={`form-control pt-2 pb-2 ${invalid ? "is-invalid" : ""}`}
        options={{
          mode: "single",
          altInput: true,
          minDate,
          maxDate,
          static: staticDateDisplay,
          // allowInput: true,
          dateFormat: "Y-m-d",
          altFormat: "m/d/Y",
        }}
      />
      {showClear && date && <Button onClick={handleClear}>Clear</Button>}
    </InputGroup>
  );
};

export default FormikDatePickerField;
