import { Card, Spinner, Table } from "react-bootstrap";
import { TaxPlanResult } from "../financeDashboard.type";
import { getInternationalActivityTableRows } from "./utils";

type InternationalActivityTableProps = {
  taxPlanResultData?: TaxPlanResult;
  taxPlanResultDataIsFetching: boolean;
}

const InternationalActivityTable = ({
  taxPlanResultData,
  taxPlanResultDataIsFetching,
}: InternationalActivityTableProps) => {
  const hasInternationalActivity = () => {
    // Checks the internationalActivity data and returns false if user doesn't
    // have any foreign subsidiaries, shareholders, and foreign bank accounts
    const internationalActivityFromPayroll = {
      foreignShareholders: taxPlanResultData?.numberOfForeignShareholders,
      internationalSubsidiaries: taxPlanResultData?.numberOfInternationalSubsidiaries,
      foreignBankAccounts: taxPlanResultData?.numberOfForeignBankAccounts,
    };

    return Object.values(internationalActivityFromPayroll).some((value) => (value && value > 0));
  };

  if (taxPlanResultDataIsFetching) {
    return (
      <Card>
      <Card.Header>
        <h4 className="card-header-title">
          International Activity
        </h4>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="text-center p-5">
          <Spinner variant="primary" animation="border" role="status" />
        </div>
      </Card.Body>
    </Card>
    );
  }

  if (taxPlanResultData && !hasInternationalActivity()) {
    return (<></>);
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="card-header-title">
          International Activity
        </h4>
      </Card.Header>
      <Card.Body className="p-0">
        <Table className='table no-wrap mb-0' size="sm">
          <thead>
            <tr>
              <th>Form</th>
              <th className="text-center">Quantity</th>
              <th>Due dates</th>
              <th>Failure to File Penalty</th>
              <th className="text-center">Estimated Taxes Owed</th>
            </tr>
          </thead>
          <tbody>
            {taxPlanResultData && getInternationalActivityTableRows(taxPlanResultData)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default InternationalActivityTable;
