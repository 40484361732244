import { Form, useFormikContext } from "formik";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FieldTypes } from "../forms/formik-components/formTypes";
import { PassportFieldTypeEnum, PassportFieldTypesDisplayMap } from "./passport.type";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";

export type CreatePassportFieldFormValues = {
    displayName: string;
    fieldType: PassportFieldTypeEnum;
    fieldValue: string;
};

const typeOptions = Object.entries(PassportFieldTypesDisplayMap).map(([key, displayName]) => (
    <option key={key} value={key}>{displayName}</option>
));

type CreatePassportFieldFormProps = {
    setPassportFieldType: (fieldType: PassportFieldTypeEnum) => void;
    labels: { [key: string]: string; };
    types: { [key: string]: FieldTypes; };
    placeholders: { [key: string]: string; };
    handleClose: () => void;
    creationFailureMessage: string;
};

export const CreatePassportFieldForm = ({
  setPassportFieldType,
  labels,
  types,
  placeholders,
  handleClose,
  creationFailureMessage,
}: CreatePassportFieldFormProps) => {
  const formikContext = useFormikContext<CreatePassportFieldFormValues>();

  useEffect(() => {
    setPassportFieldType(formikContext.values.fieldType);
  }, [formikContext.values.fieldType, setPassportFieldType]);

  return (
        <>
            <Form>
                <Modal.Body>
                    <FondoFormGroup
                        formikContext={formikContext}
                        fieldName="displayName"
                        labels={labels}
                        types={types}
                        placeholders={placeholders} />
                    <FondoFormGroup
                        formikContext={formikContext}
                        fieldName="fieldType"
                        labels={labels}
                        types={types}
                        placeholders={placeholders}
                        selectorOptions={typeOptions} />
                    <FondoFormGroup
                        formikContext={formikContext}
                        fieldName="fieldValue"
                        labels={labels}
                        types={types}
                        placeholders={placeholders} />
                    {creationFailureMessage && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {creationFailureMessage}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary">Cancel</Button>
                    <Button type="submit" variant="primary" disabled={!formikContext.isValid}>Save</Button>
                </Modal.Footer>
            </Form>
        </>);
};
