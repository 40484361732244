import { Col, Row } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import OrderCard from "./OrderCard";
import ProductsCard from "./ProductsCard";
import "../onboarding.css";
import { OnboardingForm } from "../../dynamic-onboarding-forms/dynamicOnboardingForm.type";
import { ONBOARDING_FORM } from "../../../constants/network-calls";

const Checkout = () => {
  const onboardingFormData = useOutletContext<OnboardingForm | undefined>();
  const goToPreviousForm = (formData: OnboardingForm) => {
    const onboardingFormSlug = formData.slug;
    const lastSection = formData.onboardingForm.sections.at(-1);
    const lastPage = lastSection?.pages.at(-1);
    return `/${ONBOARDING_FORM}${onboardingFormSlug}/${lastSection?.slug}/${lastPage?.order}/`;
  };
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 card p-0">
        <Row className="gx-0">
          <Col className="container-bg">
            <ProductsCard />
            <div className="ms-5 mb-4">
              <Link to={onboardingFormData ? goToPreviousForm(onboardingFormData) : "/onboarding/tax-plan"}>
                <FeatherIcon className="mb-1" icon="chevron-left" size="15" />
                Review your information
              </Link>
            </div>
          </Col>
          <Col>
            <OrderCard />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Checkout;
