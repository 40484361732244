import React from "react";

enum FieldTypes {
  CURRENCY = "currency",
  TEXT = "text",
  TEXTAREA = "textarea",
  YESNO = "yes/no",
  EIN = "ein",
  DATE = "date",
  STATE = "state",
  DATE_OF_YEAR = "date_of_year",
  INTEGER = "integer",
  SELECT_PERCENTAGE = "select_percentage",
  COUNTRY = "country",
  BOOLEAN = "boolean",
  SSN = "ssn",
  CHECKBOX = "checkbox",
  BASIC_SELECTOR = "basic_selector",
  YESNO_FROM_BOOLEAN = "yes/no_from_boolean",
  YEAR_SELECTOR = "year_selector",
  ENCRYPTED_TEXT = "encrypted_text",
  STAR_RATING = "star_rating",
  ARRAY = "array",
}

export type HelperText = {
  type: "whereToFindThis"|"whyDoWeNeedThis"|"whatIsThis";
  body: React.ReactNode|string;
}

export { FieldTypes };
