import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createSignupPersonalDetails } from "../../onboarding/signup/signup.api";
import { PersonalDetailError, PersonalDetailInput } from "../../onboarding/signup/signup.type";
import { DASHBOARD_ONBOARDING_COMPANY } from "../../../constants/network-calls";

const Signup = () => {
  const navigate = useNavigate();
  const signupMutation = useMutation<any, AxiosError<PersonalDetailError>, PersonalDetailInput>((
    data,
  ) => createSignupPersonalDetails(data));

  return (
        <div>
            <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  companyName: "",
                  email: "",
                  title: "",
                  password: "",
                  dashboardOnboarding: true,
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().trim().required("This field is required"),
                  lastName: Yup.string().trim().required("This field is required"),
                  companyName: Yup.string().trim().required("This field is required"),
                  email: Yup.string().trim().email("Invalid email address").required("This field is required"),
                  title: Yup.string().trim().required("This field is required"),
                  password: Yup.string().trim().required("This field is required"),
                })}
                onSubmit={(values, { setErrors }) => {
                  signupMutation.mutate(values, {
                    onError: (err) => {
                      const errors = err?.response?.data;
                      if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                        setErrors(errors as Record<keyof PersonalDetailInput, string | undefined>);
                      }
                    },
                    onSuccess: () => {
                      navigate(DASHBOARD_ONBOARDING_COMPANY);
                    },
                  });
                }}>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8 mx-3">
                        <h2>Welcome</h2>
                        <p className="text-muted">Please tell us a bit about yourself</p>
                        <Form className="card-body  my-5">
                            <div className="row my-5">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="firstName">First Name</label>
                                    <Field
                                      name="firstName"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"First Name"}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="firstName" />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field
                                      name="lastName"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"Last Name"}
                                    />
                                    <ErrorMessage component="div" className="text-danger mt-1" name="lastName" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="title">Title</label>
                                    <Field name="title" type="text" className={"form-control"} placeholder={"CEO"} />
                                    <ErrorMessage component="div" className="text-danger" name="title" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="companyName">Company Name</label>
                                    <Field
                                      name="companyName"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"Fondo"}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="companyName" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="email">Email Address</label>
                                    <Field
                                      name="email"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"name@address.com"}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="email" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="password">Password</label>
                                    <Field name="password" type="password" className={"form-control"} />
                                    <ErrorMessage component="div" className="text-danger" name="password" />
                                </div>
                            </div>
                            <hr className="row my-5" />
                            <div className="row mb-3 mt-2">
                                <div className="d-flex flex-row col-12 col-md-12 justify-content-between ">
                                    <Button
                                      className="btn btn-lg btn-light"
                                      variant="white"
                                      onClick={() => navigate("/login")}
                                    >
                                      Cancel
                                    </Button>
                                    <Button className="btn btn-lg btn-primary" type="submit">
                                        {signupMutation.isLoading ? <Spinner /> : <div>Next</div>}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Formik>
        </div>
  );
};

export default Signup;
