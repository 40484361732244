import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { RevenueHeroSchedulerButton } from "../revenue-hero/RevenueHeroSchedulerButton";
import { RevenueHeroSubmitData } from "../revenue-hero/revenue-hero.types";
import FondoToast from "../fusion-kit/FondoToast";

type TalkWithAnExpertProps = {
  signupData: RevenueHeroSubmitData;
  emailInvalid?: boolean;
  onRevenueHeroError?: (error: any) => void;
  className?: string;
};

export const TalkWithAnExpert = ({
  signupData,
  emailInvalid = false,
  onRevenueHeroError,
  className,
}: TalkWithAnExpertProps) => {
  const [showErrorToast, setShowErrorToast] = useState(false);

  return (
    <>
      <Container className={className}>
        <Row xs="auto">
          <Col className="d-flex align-items-center justify-content-end">
            <div className="fs-2">Need help getting started?</div>
          </Col>
          <Col className="d-flex align-items-center">
            <RevenueHeroSchedulerButton
              size="sm"
              signupData={signupData}
              disabled={emailInvalid}
              onRevenueHeroError={(e) => {
                onRevenueHeroError?.(e);
                setShowErrorToast(true);
              }}
            >
              Talk with an expert
            </RevenueHeroSchedulerButton>
          </Col>
        </Row>
        {emailInvalid && (
          <Row xs={1} className="text-danger mt-2">
            <Col>Please enter a valid email address to schedule a call</Col>
          </Row>
        )}
      </Container>
      <FondoToast
        show={showErrorToast}
        handleClose={() => setShowErrorToast(false)}
        title="Error while scheduling call"
        message="Please check your email address & try again."
        error
      />
    </>
  );
};
