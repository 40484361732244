import { Components } from "../../types/openapi.d";
import { FieldTypes } from "../forms/formik-components/formTypes";

export type PassportFieldTypes = Components.Schemas.PassportFieldFieldTypeEnum;

export enum PassportFieldTypeEnum {
    TEXT = "TEXT",
    DATE = "DATE",
    CURRENCY_AMOUNT = "CURR",
    NUMBER = "NUMB",
    URL = "URL",
    ENC_TEXT = "ENCT",
}

export const PassportFieldTypesDisplayMap: Record<PassportFieldTypes, string> = {
  [PassportFieldTypeEnum.TEXT]: "Text",
  [PassportFieldTypeEnum.DATE]: "Date",
  [PassportFieldTypeEnum.CURRENCY_AMOUNT]: "Currency Amount",
  [PassportFieldTypeEnum.NUMBER]: "Number",
  [PassportFieldTypeEnum.URL]: "URL",
  [PassportFieldTypeEnum.ENC_TEXT]: "Encrypted Text",
};

export const PassportFieldTypesToFondoFormGroupTypes: { [key in PassportFieldTypeEnum]: FieldTypes } = {
  [PassportFieldTypeEnum.TEXT]: FieldTypes.TEXT,
  [PassportFieldTypeEnum.DATE]: FieldTypes.DATE,
  [PassportFieldTypeEnum.CURRENCY_AMOUNT]: FieldTypes.CURRENCY,
  [PassportFieldTypeEnum.NUMBER]: FieldTypes.INTEGER,
  [PassportFieldTypeEnum.URL]: FieldTypes.TEXT,
  [PassportFieldTypeEnum.ENC_TEXT]: FieldTypes.ENCRYPTED_TEXT,
};

export type PassportField = Components.Schemas.PassportField;
