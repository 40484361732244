import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useParams } from "react-router";
import FondoToast from "../../fusion-kit/FondoToast";
import SuccessfulSubmissionImg from "../../imgs/rnd-submission-successful.png";
import {
  RnDFormValues,
} from "./baseFormData";
import RnDEligiblityModal from "./eligibility/RnDEligiblityModal";
import RDPage1 from "./RDPage1";
import RDPage2 from "./RDPage2";
import { getRnDForm } from "./rndForm.api";
import RnDFormHeader from "./RnDFormHeader";

const RnDForm = () => {
  const {taxYear} = useParams()
  const parsedTaxYear = Number(taxYear)
  // 2023 in useQuery is to get the form created in 2023 (is used for multiple years)
  // to save data based on tax year it's sent to getRnDForm()
  const formQuery = useQuery(["GetRnDForm", 2023], () => getRnDForm(parsedTaxYear), {
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: (data) => {
      if (data.submitted) {
        setCurrentPage(3);
      } else if (data.createImproveProduct && data.experimentalProcesses && data.facedUncertainty
        && data.revenueMoreThanFiveMillion && data.technologicalActivities && data.paidOnSupplies
        && data.paidToContractors && data.paidToEmployees) {
        setCurrentPage(2);
      }
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [isEligible, _setIsEligible] = useState(true);
  const [hasBeenShown, setHasBeenShown] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const setIsEligible = (eligible: boolean): void => {
    if (isEligible !== eligible) {
      setHasBeenShown(false);
      _setIsEligible(eligible);
    }
  };

  if (!formQuery.data) {
    return <>LOADING</>;
  }

  const initialValues: RnDFormValues = {
    ...formQuery.data,
    wagesToDelete: [],
    contractorsToDelete: [],
    suppliesToDelete: [],
  };

  const goToPriorPage = () => setCurrentPage(Math.max(1, currentPage - 1));
  const goToNextPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = Math.min(3, prevPage + 1);
      if (newPage === 2 && !hasBeenShown && !initialValues.submitted) {
        setShowEligibilityModal(true);
      }
      return newPage;
    });
  };

  return (
    <>
    <Formik initialValues={initialValues} onSubmit={() => undefined}>
      <Form>
        <div className='px-3 py-5 px-md-5 py-md-4' style={{ maxWidth: "1000px" }}>
          <RnDFormHeader
            currentPage={currentPage}
            setEligibilityStatus={setIsEligible}
            setShown={setHasBeenShown}
            setShowErrorToast={setShowErrorToast}
          />
          <div style={{ minHeight: "500px" }}>
            {currentPage === 1 && <RDPage1 />}
            {currentPage === 2 && <RDPage2 />}
            {currentPage === 3 && formQuery.data.submitted && (
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <img
                src={SuccessfulSubmissionImg}
                className="img-fluid"
                alt="Submission Successful"
                style={{ width: "50%" }}
              />
              <h3 className="mt-4 mb-3 text-center">Thank you!</h3>
              <h5 className="text-center text-secondary">
                If we need anything else, we will reach out.
              </h5>
            </div>
            )}
          </div>
          <footer className="text-center">
            <hr />
            {currentPage > 1 && (<Button
              size='lg'
              className="float-start"
              variant="outline-primary"
              onClick={goToPriorPage}
            >
              Previous
            </Button>)}
            {currentPage < 3 && (<Button
              size='lg'
              className="float-end"
              onClick={goToNextPage}
            >
              Next
            </Button>)}
            <Row style={{ maxWidth: "1000px" }}>
              <h5 className='mt-5 header-pretitle text-center'>R&D Form: Page {currentPage} of 3</h5>
              <h5 style={{ color: "#A1B0CA" }} className="mt-5 text-center">
                Need help? Free free to book a call
                <a
                  href="https://calendly.com/lisa-cpa"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#2c7be5" }}> here.</a>
              </h5>
            </Row>
          </footer>
        </div>
        <RnDEligiblityModal
          show={showEligibilityModal}
          onClose={() => {
            setShowEligibilityModal(false);
            setHasBeenShown(true);
          }}
          eligiblity={isEligible}
        />
      </Form>
    </Formik>
    <FondoToast
    show={showErrorToast}
    handleClose={() => setShowErrorToast(false)}
    title="Error During Submission"
    message={"Your progress has not been saved!"}
    error={true}
  />
  </>
  );
};

export default RnDForm;
