import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal } from "react-bootstrap";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { Deal, RequiredDataUpdate, UnavailableRequiredData } from "../passport-partners.types";
import { DataPullerInfo, DataPullerTypeEnum } from "../../../types/data-pullers.type";
import { updateRequiredData } from "../passport-partners.api";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { followPath, followPathAndSetValue } from "../../forms/formik-components/utils";
import { HelperText } from "../../forms/formik-components/formTypes";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import {
  DATA_PULLER_ADDRESS_NAMES,
  getFormFieldLabels,
  getFormFieldNames,
  getFormFieldTypes,
  PHYSICAL_ADDRESS_KEY,
} from "../../../utils/data-pullers.utils";

const DEFAULT_SELECTED_COUNTRY = "US";

const convertValuesToRequiredDataUpdates = (
  values: Record<string, any>,
  topLevelRequiredDataInfos: UnavailableRequiredData[],
): RequiredDataUpdate[] =>
  topLevelRequiredDataInfos.map((requiredData) =>
    // only send top-level values
    ({
      uuid: requiredData.uuid || "",
      newData: values[requiredData.dataInfo.keyName],
    }),
  );

const getInitialValues = (missingRequiredDataInfos: DataPullerInfo[]) =>
  missingRequiredDataInfos.reduce((acc, currData): Record<string, any> => {
    let value: Record<string, any> | string = "";
    if (currData.dataType === DataPullerTypeEnum.OBJECT) {
      value = getInitialValues(currData.objectSchema || []);
    } else if (currData.keyName === "country" && !currData.dataValue) {
      value = DEFAULT_SELECTED_COUNTRY;
    }
    return {
      ...acc,
      [currData.keyName]: value || currData.dataValue,
    };
  }, {} as Record<string, any>);

type MissingInfoModalProps = {
  show: boolean;
  onHide: () => void;
  deal: Deal;
  missingRequiredData: UnavailableRequiredData[];
};

export const MissingInfoModal = ({ show, onHide, deal, missingRequiredData }: MissingInfoModalProps) => {
  const dataInfos = missingRequiredData.map((data) => data.dataInfo);
  const queryClient = useQueryClient();
  const updateRequiredDataMutation = useMutation(
    (requiredDataUpdates: RequiredDataUpdate[]) => updateRequiredData(deal.uuid, requiredDataUpdates),
    { onSettled: () => queryClient.invalidateQueries(["passportPartnersUnavailableRequiredData", deal.uuid]) },
  );

  const getEditField = (
    missingRequiredDataInfo: DataPullerInfo,
    formikContext: FormikProps<any>,
    keyNamePrefix = "",
  ) => {
    const baseKeyName = `${keyNamePrefix}${missingRequiredDataInfo.keyName}`;
    const helperText: HelperText = { type: "whatIsThis", body: missingRequiredDataInfo.description };
    if (missingRequiredDataInfo.dataType === DataPullerTypeEnum.OBJECT) {
      if (missingRequiredDataInfo.keyName === PHYSICAL_ADDRESS_KEY) {
        return (
          <AddressFieldGroup
            key="address-field-group"
            formikContext={formikContext}
            baseName={baseKeyName}
            labels={getFormFieldLabels(missingRequiredDataInfo.objectSchema || [])}
            types={getFormFieldTypes(missingRequiredDataInfo.objectSchema || [])}
            names={DATA_PULLER_ADDRESS_NAMES}
          />
        );
      }
      return (
        <>
          <h3>{missingRequiredDataInfo.displayName}</h3>
          {missingRequiredDataInfo.objectSchema?.map((dataInfo) => (
            <React.Fragment key={`${baseKeyName}.${dataInfo.keyName}`}>
              {getEditField(dataInfo, formikContext, `${baseKeyName}.`)}
            </React.Fragment>
          ))}
        </>
      );
    }
    return (
      <FondoFormGroup
        key={`form-group-${baseKeyName}`}
        formikContext={formikContext}
        fieldName={baseKeyName}
        labels={getFormFieldLabels(dataInfos)}
        types={getFormFieldTypes(dataInfos)}
        helperText={helperText}
        selectorOptions={missingRequiredDataInfo.enumChoices?.map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
        includeSelectorEmptyValue={true}
      />
    );
  };

  const validateValues = (values: Record<string, any>) => {
    const errors = {} as Record<string, string>;
    dataInfos.forEach((dataInfo) =>
      getFormFieldNames(dataInfo).forEach((keyName) => {
        const pathArr = keyName.split(".");
        const currVal = followPath(values, pathArr);
        if (!currVal) {
          followPathAndSetValue(errors, pathArr, "Required", true);
        }
      }),
    );
    return errors;
  };

  const handleSubmit = (values: Record<string, any>) => {
    updateRequiredDataMutation.mutate(convertValuesToRequiredDataUpdates(values, missingRequiredData));
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} className="modal-over-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add Missing Information</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues(dataInfos)}
        validate={validateValues}
        validateOnMount
      >
        {(formikContext) => (
          <Form>
            <Modal.Body>{dataInfos.map((data) => getEditField(data, formikContext))}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={!formikContext.dirty || !formikContext.isValid}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
