import { ReactNode, useContext, useEffect } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { getOrLoadRevenueHeroScript, removeRevenueHeroScript } from "./revenue-hero.utils";
import { RevenueHeroSubmitData } from "./revenue-hero.types";
import { RevenueHeroScriptContext } from "./RevenueHeroScriptProvider";

type RevenueHeroButtonProps = ButtonProps & {
  signupData: RevenueHeroSubmitData;
  children: ReactNode;
  onRevenueHeroError?: (error: any) => void;
};

export const RevenueHeroSchedulerButton = ({
  signupData,
  children,
  onRevenueHeroError,
  ...buttonProps
}: RevenueHeroButtonProps) => {
  const revenueHeroScriptLoaded = useContext(RevenueHeroScriptContext);
  useEffect(() => {
    if (revenueHeroScriptLoaded) return;
    getOrLoadRevenueHeroScript().catch((error) => {
      onRevenueHeroError?.(error);
    });
    return removeRevenueHeroScript;
  }, []);

  return (
    <Button
      {...buttonProps}
      onClick={async () => {
        try {
          const hero = new window.RevenueHero({
            routerId: "2030",
          });
          const data = await hero.submit(signupData);
          hero.dialog.open(data);
        } catch (error) {
          onRevenueHeroError?.(error);
        }
      }}
    >
      {children}
    </Button>
  );
};
