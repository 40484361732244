import { buildYup } from "schema-to-yup";
import { DataPullerInfo, DataPullerTypeEnum } from "../../types/data-pullers.type";
import { DynamicOnboardingForm, Page, RowField, Section } from "./dynamicOnboardingForm.type";

export const CHECKOUT = "checkout";
export const SIGNUP = "signup";

export const trimUrlSlash = (url: string) => {
  if (url.endsWith("/")) {
    return url.slice(0, -1);
  }
  return url;
};

export const getSection = (dynamicOnboardingForm: DynamicOnboardingForm, sectionName: string) =>
  dynamicOnboardingForm.sections.find((section) => section.slug === sectionName);

export const getPage = (section: Section, pageNumber: string) =>
  section.pages.find((page) => page.order === Number(pageNumber));

export const getNextPageAndSection = (
  dynamicOnboardingForm: DynamicOnboardingForm,
  currentSection: Section,
  currentPage: Page,
): {
  nextPage?: Page;
  nextSection?: Section;
} => {
  const nextPage = currentSection.pages.find((page) => page.order === currentPage.order + 1);
  const nextSection = dynamicOnboardingForm.sections.find((section) => section.order === currentSection.order + 1);

  if (nextPage) return { nextPage, nextSection: currentSection };
  if (nextSection) return { nextPage: nextSection.pages[0], nextSection };
  return {};
};

export const getPreviousPageAndSection = (
  dynamicOnboardingForm: DynamicOnboardingForm,
  currentSection: Section,
  currentPage: Page,
): {
  previousPage?: Page;
  previousSection?: Section;
} => {
  const previousPage = currentSection.pages.find((page) => page.order === currentPage.order - 1);
  const previousSection = dynamicOnboardingForm.sections.find((section) => section.order === currentSection.order - 1);

  if (previousPage) return { previousPage, previousSection: currentSection };
  if (previousSection) return { previousPage: previousSection.pages.at(-1), previousSection };
  return {};
};

export const getCurrentSectionAndPageFromSlugs = (
  dynamicOnboardingForm: DynamicOnboardingForm,
  sectionSlug?: string,
  pageSlug?: string,
): {
  currentSection?: Section;
  currentPage?: Page;
} => {
  if (!sectionSlug || !pageSlug) return {};

  const currentSection = getSection(dynamicOnboardingForm, sectionSlug);
  if (!currentSection) return {};

  const currentPage = getPage(currentSection, pageSlug);
  if (!currentPage) return {};

  return { currentSection, currentPage };
};

export const getInitialJsonSchema = (): any => ({
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {},
  required: [],
});

const getErrDisplayName = (displayName: string) => (displayName.endsWith("?") ? "This field" : displayName);

export const constructErrorMessagesConfig = (rowFields: RowField[]): Record<string, Record<string, string>> =>
  Object.fromEntries(
    rowFields.flatMap(
      ({
        field: {
          validationSchema,
          customErrorMessageConfig,
          dataInfo: { dataType, keyName, objectSchema, displayName },
        },
      }) => {
        if (customErrorMessageConfig) {
          if (customErrorMessageConfig.type === "object") {
            delete customErrorMessageConfig.type;
            return Object.entries(customErrorMessageConfig as Record<string, Record<string, string>>);
          }
          return [[keyName, customErrorMessageConfig as Record<string, string>]];
        }
        const schemaInfos = [{ keyName, displayName, validationSchema }];
        if (dataType === DataPullerTypeEnum.OBJECT) {
          schemaInfos.push(
            ...(objectSchema || []).map(({ keyName: key, displayName: display }) => ({
              keyName: key,
              displayName: display,
              validationSchema: validationSchema.properties?.[keyName] || {},
            })),
          );
        }
        return schemaInfos.map(({ keyName: key, displayName: display, validationSchema: schema }) => {
          const errDispName = getErrDisplayName(display);
          return [
            key,
            {
              required: `${errDispName} is required.`,
              maxDate: `${errDispName} must be before ${schema.maxDate}.`,
              minimum: `${errDispName} must be at least ${schema.minimum}.`,
              exclusiveMinimum: `${errDispName} must be greater than ${schema.exclusiveMinimum}.`,
              maximum: `${errDispName} must be at most ${schema.maximum}.`,
              exclusiveMaximum: `${errDispName} must be less than ${schema.exclusiveMaximum}.`,
              minLength: `${errDispName} must be at least ${schema.minLength} characters.`,
              maxLength: `${errDispName} must be at most ${schema.maxLength} characters.`,
              minItems: `${errDispName} must have at least ${schema.minItems} item${schema.minItems > 1 ? "s" : ""}.`,
              maxItems: `${errDispName} can have at most ${schema.maxItems} item${schema.maxItems > 1 ? "s" : ""}.`,
            },
          ];
        });
      },
    ),
  );

export const constructYupSchemaFromRowFields = (rowFields: RowField[]) => {
  const jsonSchema = getInitialJsonSchema();
  const config = {
    errMessages: constructErrorMessagesConfig(rowFields),
  };
  rowFields.forEach(
    ({
      required,
      field: {
        validationSchema,
        dataInfo: { dataType, description, keyName, displayName },
      },
    }) => {
      jsonSchema.properties[keyName] = validationSchema || {
        type: dataType,
        title: displayName,
        description,
      };
      if (required) {
        jsonSchema.required.push(keyName);
      }
    },
  );
  return buildYup(jsonSchema, config);
};

export const getMaxDateFromRowField = ({
  field: {
    validationSchema,
    dataInfo: { dataType },
  },
}: RowField): string | undefined => {
  if (dataType !== DataPullerTypeEnum.DATE) return undefined;
  return validationSchema?.inclusiveMaxDate;
};

export const getObjectInitialValues = (objectInfos: DataPullerInfo[]) =>
  Object.fromEntries(objectInfos.map((curr) => [curr.keyName, processFieldDataInfoToValue(curr)]));

export const processFieldDataInfoToValue = (fieldDataInfo: DataPullerInfo): any => {
  if (fieldDataInfo.dataType === DataPullerTypeEnum.OBJECT && typeof fieldDataInfo.dataValue === "object") {
    // Objects will just add their key-value pairs to the formik initialValues.
    return getObjectInitialValues(fieldDataInfo.objectSchema || []);
  }
  if (fieldDataInfo.dataType === DataPullerTypeEnum.BOOLEAN) {
    // Special processing for the boolean type so we can use FondoFormGroup's YESNO field.
    return fieldDataInfo.dataValue ? "True" : "False";
  }
  return fieldDataInfo.dataValue;
};
