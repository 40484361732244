import { Card, Button, Row, Col, Container } from "react-bootstrap";
import { Form, Formik, FormikProps } from "formik";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { AxiosError } from "axios";
import { RowField } from "../dynamicOnboardingForm.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { DataPullerTypeEnum } from "../../../types/data-pullers.type";
import { getPageField, updatePageFields } from "../dynamicOnboardingForm.api";
import Spinner from "../../spinner/Spinner";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import {
  DATA_PULLER_ADDRESS_NAMES,
  getFormFieldLabels,
  getFormFieldTypes,
  PHYSICAL_ADDRESS_KEY,
} from "../../../utils/data-pullers.utils";
import FondoToast from "../../fusion-kit/FondoToast";
import {
  constructYupSchemaFromRowFields,
  getMaxDateFromRowField,
  processFieldDataInfoToValue,
} from "../dynamicOnboardingForm.utils";
import { Components } from "../../../types/openapi.d";
import { useExistingSignupPersonalDetails } from "../../onboarding/signup/signup.api";
import { TalkWithAnExpert } from "../TalkWithAnExpert";
import { RevenueHeroSubmitData } from "../../revenue-hero/revenue-hero.types";

type PageFieldUpdateResponse = Components.Schemas.PageFieldUpdateResponse;

type DynamicOnboardingFormPageProps = {
  pageUuid: string;
  firstPage: boolean;
  lastPage: boolean;
  showCheckout: boolean;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  goToCheckout: () => void;
  goToDashboard: () => void;
};

const DynamicOnboardingFormPage = ({
  pageUuid,
  firstPage,
  lastPage,
  showCheckout,
  handlePreviousPage,
  handleNextPage,
  goToCheckout,
  goToDashboard,
}: DynamicOnboardingFormPageProps) => {
  const queryClient = useQueryClient();
  const { data: pageFieldData, isLoading: pageFieldDataIsLoading } = useQuery(
    ["pageField", pageUuid],
    getPageField(pageUuid),
  );
  const signupQuery = useExistingSignupPersonalDetails();
  const emailAddress = signupQuery.data?.email;
  const isFormDirtyRef = useRef(false);
  const [errorToastMessage, setErrorToastMessage] = useState<string | null>(null);

  const handleNextButton = () => {
    if (lastPage) {
      // eslint-disable-next-line no-unused-expressions
      showCheckout ? goToCheckout() : goToDashboard();
    } else {
      handleNextPage();
    }
  };

  const pageFieldsUpdate = useMutation({
    mutationFn: (values: Record<string, any>) => updatePageFields(pageUuid, values),
    onSuccess: () => {
      queryClient.invalidateQueries(["pageField", pageUuid]);
      handleNextButton();
    },
    onError: (err: AxiosError<PageFieldUpdateResponse>) => {
      setErrorToastMessage(err.response?.data.detail || "");
    },
  });

  const rowFields = (pageFieldData?.pageRows || []).flatMap((pageRow) => pageRow.rowFields);

  const rowFieldUuidToSelfMap = Object.fromEntries(rowFields.map((rowField) => [rowField.uuid, rowField]));

  const isRowFieldActive = ({ parentRowFieldId, field }: RowField): boolean => {
    if (parentRowFieldId) {
      const parentField =
        Object.hasOwn(rowFieldUuidToSelfMap, parentRowFieldId) && rowFieldUuidToSelfMap[parentRowFieldId];
      // If the parent field is not found, we assume it's inactive
      return field.active && parentField && isRowFieldActive(parentField);
    }
    return field.active;
  };
  const activeRowFields = rowFields.filter((rowField) => isRowFieldActive(rowField));

  const dataPullerInfos = activeRowFields.map(({ field: { dataInfo } }) => dataInfo);

  const formFieldTypes = getFormFieldTypes(dataPullerInfos);
  const formFieldLabels = getFormFieldLabels(dataPullerInfos);

  const shouldRowFieldShow = (values: Record<string, any>, rowField: RowField): boolean => {
    if (!isRowFieldActive(rowField)) return false;
    const { parentRowFieldId, parentExpectedValues } = rowField;
    // if there's no parentRowFieldId, then this row field is a top-level field
    if (!parentRowFieldId) return true;
    // isRowFieldActive already checked membership of parentRowFieldId in rowFieldUuidToSelfMap
    const parentField = rowFieldUuidToSelfMap[parentRowFieldId];
    const parentValue = values[parentField.field.dataInfo.keyName];
    const thisShouldShow = parentExpectedValues.includes(parentValue);
    return thisShouldShow && shouldRowFieldShow(values, parentField);
  };

  const initialValues = Object.fromEntries(
    activeRowFields.map(({ field: { dataInfo } }) => [dataInfo.keyName, processFieldDataInfoToValue(dataInfo)]),
  );

  const formValues = useRef<Record<string, any>>(initialValues);

  const getDisplayedRowFieldStructure = (values: Record<string, any>): RowField[][] =>
    (pageFieldData?.pageRows || []).map((pageRow) =>
      pageRow.rowFields.filter((rowField) => shouldRowFieldShow(values, rowField)),
    );

  const getBodyContent = (formikContext: FormikProps<any>) => {
    if (!pageFieldData || pageFieldData.pageRows.length === 0) {
      return (
        <Container className="d-flex flex-column justify-content-center text-center" style={{ minHeight: "20rem" }}>
          <h1>No data found for this page!</h1>
          <p>This page appears to be blank, please contact the Fondo Engineering Team.</p>
        </Container>
      );
    }
    return getDisplayedRowFieldStructure(formikContext.values).map((displayedRowFields, idx) => {
      if (displayedRowFields.length === 0) return null;
      return (
        <Row key={`page-row-${idx}`}>
          {displayedRowFields.map((rowField) => {
            // We specifically use the AddressFieldGroup component for the physicalAddress field.
            // If we end up with more complex objects (hopefully not), we should consider a more generic solution
            // or adding a specialized component for each object type.
            const { dataType, keyName, enumChoices, arrayOptions, labelKey } = rowField.field.dataInfo;
            if (dataType === DataPullerTypeEnum.OBJECT && keyName === PHYSICAL_ADDRESS_KEY) {
              return (
                <AddressFieldGroup
                  key={`row-field-${rowField.uuid}`}
                  formikContext={formikContext}
                  types={formFieldTypes}
                  labels={formFieldLabels}
                  baseName={PHYSICAL_ADDRESS_KEY}
                  required={rowField.required}
                  names={DATA_PULLER_ADDRESS_NAMES}
                />
              );
            }
            return (
              <Col key={`row-field-${rowField.uuid}`}>
                <FondoFormGroup
                  formikContext={formikContext}
                  key={keyName}
                  fieldName={keyName}
                  types={formFieldTypes}
                  labels={formFieldLabels}
                  selectorOptions={enumChoices?.map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                  arrayOptions={arrayOptions}
                  labelKey={labelKey}
                  includeSelectorEmptyValue={true}
                  required={rowField.required}
                  maxDate={getMaxDateFromRowField(rowField)}
                />
              </Col>
            );
          })}
        </Row>
      );
    });
  };

  const fieldNameToRowFieldMap = Object.fromEntries(
    activeRowFields.map((rowField) => [rowField.field.dataInfo.keyName, rowField]),
  );

  const submitForm = (values: Record<string, any>) => {
    // skip update if form is not dirty
    if (!isFormDirtyRef.current) {
      handleNextButton();
      return;
    }
    // need to filter out key names corresponding to hidden child row fields
    const valuesToUpdate = Object.fromEntries(
      Object.entries(values).filter(([key]) => {
        // prevent hidden child row fields from being updated
        const relatedField = Object.hasOwn(fieldNameToRowFieldMap, key) && fieldNameToRowFieldMap[key];
        return relatedField && shouldRowFieldShow(values, relatedField);
      }),
    );
    pageFieldsUpdate.mutate(valuesToUpdate);
  };

  const getValidationSchema = () => {
    const allDisplayedRowFields = getDisplayedRowFieldStructure(formValues.current).flat();
    return constructYupSchemaFromRowFields(allDisplayedRowFields);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Formik
          onSubmit={submitForm}
          initialValues={initialValues}
          validationSchema={getValidationSchema}
          validateOnMount
          enableReinitialize={true}
        >
          {(formikContext: FormikProps<any>) => {
            isFormDirtyRef.current = formikContext.dirty;
            formValues.current = formikContext.values;
            return (
              <Form className="w-75">
                <Card>
                  <Card.Body>{pageFieldDataIsLoading ? <Spinner /> : getBodyContent(formikContext)}</Card.Body>
                  <Card.Footer className="d-flex justify-content-between">
                    <Button
                      className={`${firstPage && "invisible"}`}
                      size="lg"
                      variant="secondary"
                      onClick={handlePreviousPage}
                    >
                      Go Back
                    </Button>
                    {pageFieldsUpdate.isLoading ? (
                      <Spinner />
                    ) : (
                      <Button
                        size="lg"
                        type="submit"
                        disabled={!formikContext.isValid}
                      >
                        {lastPage ? "Finish" : "Continue"}
                      </Button>
                    )}
                  </Card.Footer>
                  {emailAddress && (
                    <TalkWithAnExpert
                      className="ms-4 mb-4 me-4"
                      signupData={signupQuery.data as RevenueHeroSubmitData}
                    />
                  )}
                </Card>
              </Form>
            );
          }}
        </Formik>
      </div>
      <FondoToast
        show={errorToastMessage !== null}
        handleClose={() => setErrorToastMessage(null)}
        title="An error occurred while saving your information."
        message={`Review the form and try again. 
          If the issue persists, please contact the Fondo Engineering Team. 
          ${errorToastMessage}`}
        error
      />
    </>
  );
};

export default DynamicOnboardingFormPage;
