import { Components, Paths } from "../../types/openapi.d";

export type Partner = Components.Schemas.Partner;

export type Product = Components.Schemas.Product;

export enum SelectedDealStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  ACTION_NEEDED = "ACTION_NEEDED",
}

// only types are generated, not enums, so we need to define the enum ourselves
// using this type for the maps will make sure the enum is in sync with the generated types
type SelectedDealStatusEnum = Components.Schemas.SelectedDealStatusEnum;

export const SelectedDealStatusToDisplayMap: Record<SelectedDealStatusEnum, string> = {
  [SelectedDealStatus.PENDING]: "Pending",
  [SelectedDealStatus.PROCESSING]: "Processing",
  [SelectedDealStatus.REJECTED]: "Rejected",
  [SelectedDealStatus.ACCEPTED]: "Active",
  [SelectedDealStatus.ACTION_NEEDED]: "Action Needed",
};

export const SelectedDealStatusToColorMap: Record<SelectedDealStatusEnum, string> = {
  [SelectedDealStatus.PENDING]: "info",
  [SelectedDealStatus.PROCESSING]: "info",
  [SelectedDealStatus.REJECTED]: "danger",
  [SelectedDealStatus.ACCEPTED]: "success",
  [SelectedDealStatus.ACTION_NEEDED]: "primary",
};

export type RequiredData = Components.Schemas.RequiredData;

export type SelectedDeal = Components.Schemas.ReactifySelectedDeal;

export enum ReviewStatus {
  COMPLETED = "COMPLETED",
  DISMISSED = "DISMISSED",
}

export type Review = Components.Schemas.Review;
export type CompanyReview = Components.Schemas.CompanyReview;

export type ReviewData = Omit<
  Review,
  "uuid" | "partnerId" | "productId" | "userName" | "createdAt" | "updatedAt" | "reviewStatus"
>;

export type ReviewPaginatedResponse = Components.Schemas.PaginatedReviewList;

export type Deal = Components.Schemas.Deal;



export type UnavailableRequiredData = Components.Schemas.UnavailableRequiredData;

export type RequiredDataUpdate = Paths.PartialUpdateReactifyPassportPartnersUnavailableRequiredData.RequestBody[number];

export const TEN_PERCENT_OPACITY = "1A";
