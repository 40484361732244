import { FieldTypes } from "../components/forms/formik-components/formTypes";
import { Components } from "./openapi.d";

export type DataPullerType = Components.Schemas.DataTypeEnum;

export type DataPullerInfo = Components.Schemas.DataPullerInfo;

export enum DataPullerTypeEnum {
  STRING = "string",
  NUMBER = "number",
  CURRENCY = "currency",
  ENUM = "enum",
  OBJECT = "object",
  BOOLEAN = "boolean",
  DATE = "date",
  ARRAY = "array",
}

export const DataPullerTypeToFormikFieldTypeMap: Record<DataPullerType, FieldTypes> = {
  [DataPullerTypeEnum.STRING]: FieldTypes.TEXT,
  [DataPullerTypeEnum.NUMBER]: FieldTypes.INTEGER,
  [DataPullerTypeEnum.CURRENCY]: FieldTypes.CURRENCY,
  [DataPullerTypeEnum.ENUM]: FieldTypes.BASIC_SELECTOR,
  [DataPullerTypeEnum.OBJECT]: FieldTypes.TEXTAREA, // Object fields will be handled separately
  [DataPullerTypeEnum.DATE]: FieldTypes.DATE,
  [DataPullerTypeEnum.BOOLEAN]: FieldTypes.YESNO,
  [DataPullerTypeEnum.ARRAY]: FieldTypes.ARRAY,
};
