import { Field, FormikContextType } from "formik";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getCountrySubdivisions } from "../../../utils/util.api";
import { Subdivision } from "../../../utils/util.type";

type StateFieldProps = {
  selectedCountry?: string;
  formikContext: FormikContextType<any>;
  fieldName: string;
  value?: string;
  invalid: boolean;
};

export default function StateField({ formikContext, fieldName, selectedCountry, value, invalid }: StateFieldProps) {
  const countrySubdivisionQuery = useQuery<Subdivision[], Error>(
    ["countrySubdivision", selectedCountry],
    getCountrySubdivisions,
    {
      enabled: !!selectedCountry,
      refetchOnMount: false,
    },
  );

  const [currentSubdivisionCountry, currentSubdivisionAbbr] = (value || "-").split("-");

  // clear state value if country changes after initial value is set
  useEffect(() => {
    if (selectedCountry !== undefined && currentSubdivisionCountry !== selectedCountry) {
      formikContext.setFieldValue(fieldName, "");
    }
  }, [selectedCountry, currentSubdivisionCountry, formikContext.setFieldValue, fieldName]);

  function renderCountrySubdivisionList() {
    return ((selectedCountry && countrySubdivisionQuery.data) || []).map((subdivision) => (
      <option key={subdivision.abbr} value={subdivision.abbr}>
        {subdivision.name}
      </option>
    ));
  }

  const handleChange = (subdivisionAbbr: string) => {
    formikContext.setFieldValue(
      fieldName,
      selectedCountry && subdivisionAbbr ? `${selectedCountry}-${subdivisionAbbr}` : "",
    );
  };

  return (
    <Field
      name={fieldName}
      as="select"
      className={`form-control form-select ${invalid ? "is-invalid" : ""}`}
      onChange={(e: any) => handleChange(e.target.value)}
      value={currentSubdivisionAbbr}
      isInvalid={invalid}
    >
      <option key="emptyValue" value={""}>
        {selectedCountry ? "Select State" : "Please select Country"}
      </option>
      {renderCountrySubdivisionList()}
    </Field>
  );
}
