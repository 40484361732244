import FeatherIcons from "feather-icons-react";
import { matchPath, useLocation } from "react-router";
import { Section } from "../dynamicOnboardingForm.type";
import { ONBOARDING_FORM } from "../../../constants/network-calls";
import { CHECKOUT, SIGNUP } from "../dynamicOnboardingForm.utils";

type DynamicOnboardingTimelineProps = {
  currentSectionSlug: string;
  sections: Section[];
  checkoutPage: {
    showCheckoutPage: boolean;
    checkoutPageName: string;
  };
  userSignup: {
    showUserSignup: boolean;
    userSignupName: string;
  }
}

const DynamicOnboardingTimeline = ({
  currentSectionSlug,
  sections,
  checkoutPage: {
    showCheckoutPage,
    checkoutPageName,
  },
  userSignup: {
    showUserSignup: showuserSignup,
    userSignupName,
  },
}: DynamicOnboardingTimelineProps) => {
  const location = useLocation();

  const onCheckout = matchPath(`/${ONBOARDING_FORM}:onboardingSlug/${CHECKOUT}`, location.pathname)
  const onSignup = matchPath(`/${ONBOARDING_FORM}:onboardingSlug/${SIGNUP}`, location.pathname)
  const currentSection = sections.find((section) => section.slug === currentSectionSlug);

  let divClass = "step step-sm step-icon-sm step-item-between";
  if (sections.length === 1 && !showCheckoutPage &&
    !showuserSignup) divClass += " d-flex justify-content-center text-center";

  const timelineClass = (sectionItem: Section): string => {
    // These css classes are coming from `_react/packages/reactify/src/styles/step.css` on lines 603-628.
    // `is-valid` already existed before, but I created `is-complete` and `is-pending` to match the design.
    const currentSectionOrder = currentSection?.order || 0;
    if (sectionItem.order < currentSectionOrder || onCheckout) {
      return "is-complete"
    } if (sectionItem.order === currentSectionOrder) {
      return "is-valid"
    }
    return "is-pending";
  }

  const renderStepItem = (
    stepName: React.ReactNode,
    stepNumber: React.ReactNode,
    stepClass: string,
  ) => (
    <li className={`step-item ${stepClass}`} key={`${stepName}-${stepNumber}`}>
      <div className="step-content-wrapper">
        <span className="step-icon pt-1">
          {stepNumber}
        </span>
        <div className="step-content">
          <span className="step-title">
            {stepName}
          </span>
        </div>
      </div>
    </li>
  );

  const getStepOrder = (sectionStep: Section): number => showuserSignup ? sectionStep.order + 1 : sectionStep.order;

  return (
    <div className="d-flex justify-content-center mb-5">
      <div className="w-75">
        <ul className={divClass}>
          {showuserSignup && renderStepItem(
            userSignupName,
            onSignup ? "1" : <FeatherIcons icon="check" />,
            onSignup ? "is-valid" : "is-complete"
          )}
          {sections.map((sectionStep) => renderStepItem(
            sectionStep.name,
            timelineClass(sectionStep) === "is-complete" ? <FeatherIcons icon="check" /> : getStepOrder(sectionStep),
            timelineClass(sectionStep),
          ))}
          {showCheckoutPage && renderStepItem(
            checkoutPageName,
            "ϟ",
            onCheckout ? "is-valid" : "is-pending"
          )}
        </ul>
      </div>
    </div>
  );
};

export default DynamicOnboardingTimeline;
