import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useCallback, useState } from "react";

import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { createPlaidLinkToken, exchangePlaidPublicToken } from "./integrations.api";
import { ExchangePlaidPublicToken } from "./integrations.type";

type PlaidConnectionButtonProps = {
  buttonContent: string | React.ReactNode;
  accessToken?: string;
  className?: string;
  setError?: (error: string) => void;
  setOtherInstitutionLoading?: (isLoading: boolean) => void;
};

const PlaidConnectionButton = ({
  buttonContent,
  accessToken,
  className,
  setError,
  setOtherInstitutionLoading,
}: PlaidConnectionButtonProps) => {
  const queryClient = useQueryClient();
  const [token, setToken] = useState<string | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useQuery<string, Error>(["plaidLinkToken"], () => createPlaidLinkToken(accessToken), {
    refetchOnWindowFocus: false,
    onSuccess: (linkToken: string) => {
      setToken(linkToken);
    },
  });

  const publicTokenExchangeMutation = useMutation<any, AxiosError<any>, any>((payload: ExchangePlaidPublicToken) =>
    exchangePlaidPublicToken(payload),
  );

  const handleOnSubmit = () => {
    queryClient.invalidateQueries(["itemAccounts"]);
    queryClient.invalidateQueries(["amountOfConnectedAccounts"]);
    queryClient.invalidateQueries(["plaidLinkToken"]);
    setButtonLoading(false);
    if (setOtherInstitutionLoading) setOtherInstitutionLoading(false);
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    publicTokenExchangeMutation.mutate(
      { publicToken, metadata },
      {
        onSuccess: () => {
          handleOnSubmit();
        },
        onError: (e) => {
          handleOnSubmit();
          if (e.response?.data?.message) {
            if (setError) setError(e.response.data.message);
          }
        },
      },
    );
  }, []);

  const onExit = () => {
    setButtonLoading(false);
    if (setOtherInstitutionLoading) setOtherInstitutionLoading(false);
  };

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    // onEvent,
    onExit,
  });

  const handleOpenPlaidLink = () => {
    setButtonLoading(true);
    open();
  };

  return (
    <button
      id="plaid-connection-button"
      className={className || "btn btn-block btn-primary full-width"}
      onClick={handleOpenPlaidLink}
      disabled={!ready || buttonLoading}
    >
      {!ready || buttonLoading ? "Loading..." : buttonContent}
    </button>
  );
};

export default PlaidConnectionButton;
