import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import Sidenav from "../side-nav/Sidenav";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import { DJANGO_BASE_URL, REACT_BASE_URL } from "../../constants/config";
import {
  NAVIGATION,
  PATHS_TO_HIDE_SIDEBAR,
  IFRAME_MESSAGE_TYPES,
  PAGE_LOAD,
  PUBLIC_ROUTES,
  SIDENAV_COLLAPSE,
  isSidenavCollapsedPath,
  SESSION_COOKIE_NAME,
} from "../../constants/general";
import { cookies } from "../App";
import { IframeMessageType } from "./root.type";
import { matchPathInArray, removeLeadingAndTrailingSlashes } from "../../utils/common.util";
import { SidenavCollapsedContext } from "../../context/SidenavCollapsedContext";
import { ProgressTimeLineContext } from "../../context/ProgressTimelineContext";
import { getCompanyLevelSettings, getLoggedInUser } from "../account/account.api";
import { PassportContext } from "../../context/PassportContext";
import Passport from "../passport/Passport";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import { CompanyLevelSettingsContextType } from "../../context/context.type";
import { DASHBOARD_ONBOARDING, ONBOARDING_FORM, ONBOARDING_NON_DE_C_CORP } from "../../constants/network-calls";

export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentStep } = useContext(ProgressTimeLineContext);
  const [currentLoggedInUserId, setCurrentLoggedInUserId] = useState(0);
  const [currentCompanyId, setCurrentCompanyId] = useState(0);
  const [currentLoggedInUserName, setCurrentLoggedInUserName] = useState("");
  const [currentCompanyName, setCurrentCompanyName] = useState("");
  const [companyLevelSettings, setCompanyLevelSettings] = useState<CompanyLevelSettingsContextType>({
    company: 0,
    actionItemsFeatureFlag: false,
    showActionItemOnboardingHeaderFeatureFlag: false,
    showFinanceDashboardRunwayPage: true,
    showFinanceDashboardPNLPage: true,
    showFinanceDashboardBalanceSheetPage: true,
    showFinanceDashboardTaxPlanPage: true,
    showNewCheckoutPage: true,
    showNewProductPage: true,
    showFinanceDashboardTransactionsPage: true,
    showGrowthRateCardOnProfitAndLossPage: true,
    showCompoundGrowthRateCardOnProfitAndLossPage: true,
    showPassportPartners: false,
    showReportingFeatureFlag: false,
    useAiToCategorizeTransactions: false,
    showActionItemsRhoBanner: true,
    showQboIntegrationCard: false,
    showReportingAiAssistant: false,
  });
  const { setPassportOpen } = useContext(PassportContext);
  const [isSidenavCollapsed, setIsSidenavCollapsed] = React.useContext(SidenavCollapsedContext);
  const sessionCookie = cookies.get(SESSION_COOKIE_NAME);
  const [currentDashboardOnboarding, setCurrentDashboardOnboarding] = useState(false);
  const [currentCompanyDepositPaid, setCurrentCompanyDepositPaid] = useState(false);
  const [currentCompanyNonDeCCorp, setCurrentCompanyNonDeCCorp] = useState(false);
  const [currentCompanyShowNewRunway, setCurrentCompanyShowNewRunway] = useState(false);

  useQuery(["loggedInUser"], () => getLoggedInUser(), {
    refetchOnWindowFocus: false,
    enabled: !!sessionCookie,
    onSuccess: (data) => {
      setCurrentLoggedInUserId(data.currentLoggedInUserId ? data.currentLoggedInUserId : 0);
      setCurrentCompanyId(data.currentCompanyId ? data.currentCompanyId : 0);
      setCurrentLoggedInUserName(data.currentLoggedInUserName ? data.currentLoggedInUserName : "");
      setCurrentCompanyName(data.currentCompanyName ? data.currentCompanyName : "");
      setCurrentDashboardOnboarding(data.currentDashboardOnboarding);
      setCurrentCompanyDepositPaid(data.currentCompanyDepositPaid);
      setCurrentCompanyNonDeCCorp(data.currentCompanyNonDeCCorp);
      setCurrentCompanyShowNewRunway(data.currentCompanyShowNewRunway);

      if (!data.currentCompanyId && data.currentLoggedInUserId) {
        // Only happens if the user exists and they haven't filled out the company form yet
        // Currently they get stuck for forever
        handleNoCompanyId(data.currentDashboardOnboarding);
      }
      if (data.currentLoggedInUserId && (location.pathname === "/onboarding/signup"
        || location.pathname === "/dashboard-onboarding/user")) {
        preventAccessToSignup(data.currentDashboardOnboarding);
      }
    },
  });

  useQuery(["companyLevelSettings"], () => getCompanyLevelSettings(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCompanyLevelSettings({
        company: data.company || 0,
        actionItemsFeatureFlag: data.actionItemsFeatureFlag,
        showActionItemOnboardingHeaderFeatureFlag: data.showActionItemOnboardingHeaderFeatureFlag,
        showFinanceDashboardRunwayPage: data.showFinanceDashboardRunwayPage,
        showFinanceDashboardPNLPage: data.showFinanceDashboardPNLPage,
        showFinanceDashboardBalanceSheetPage: data.showFinanceDashboardBalanceSheetPage,
        showFinanceDashboardTaxPlanPage: data.showFinanceDashboardTaxPlanPage,
        showNewCheckoutPage: data.showNewCheckoutPage,
        showNewProductPage: data.showNewProductPage,
        showFinanceDashboardTransactionsPage: data.showFinanceDashboardTransactionsPage,
        showGrowthRateCardOnProfitAndLossPage: data.showGrowthRateCardOnProfitAndLossPage,
        showCompoundGrowthRateCardOnProfitAndLossPage: data.showCompoundGrowthRateCardOnProfitAndLossPage,
        showPassportPartners: data.showPassportPartners,
        showReportingFeatureFlag: data.showReportingFeatureFlag,
        useAiToCategorizeTransactions: data.useAiToCategorizeTransactions,
        showActionItemsRhoBanner: data.showActionItemsRhoBanner,
        showQboIntegrationCard: data.showQboIntegrationCard,
        showReportingAiAssistant: data.showReportingAiAssistant,
      });
    },
  });

  const handleNoCompanyId = (dashboardOnboarding: boolean) => {
    // If there is no company id, but the user is logged in,
    // the only pages accessible should be those containing
    // the form to create the company.
    if (
      (!location.pathname.includes("onboarding/company1") ||
      !location.pathname.includes("dashboard-onboarding/company")) &&
      !matchPath(`/${ONBOARDING_FORM}:onboardingSlug/*`, location.pathname)
    ) {
      if (dashboardOnboarding) {
        navigate(DASHBOARD_ONBOARDING);
      } else {
        navigate("/onboarding");
      }
    }
  };

  // If the user is already logged in, prevent them from accessing
  // to the sign up page
  const preventAccessToSignup = (dashboardOnboarding: boolean) => {
    if (dashboardOnboarding) navigate(DASHBOARD_ONBOARDING);
    else navigate("/onboarding");
  };

  useEffect(() => {
    if (sessionCookie) {
      if (location.pathname === "/login" || location.pathname === "/") {
        navigate("/dashboard");
      }
    } else if (!matchPathInArray(PUBLIC_ROUTES, location.pathname)) {
      navigate("/login");
    }
  }, [location.pathname]);

  useEffect(() => {
    // force reset sidenav collapsed state if not automatically reset.
    if (!isSidenavCollapsedPath(location.pathname)) {
      toggleSidenav(false);
    } else {
      toggleSidenav(true);
    }

    // reset passport state on every navigation
    setPassportOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("onboarding")) {
      const onboardingStep = location.pathname.split("/");
      setCurrentStep(onboardingStep[onboardingStep.length - 1]);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Redirect to the Non De C Corp warning page if the company has marked "No"
    // to the Delaware C-Corp question.
    // Allow these kind of companies to visit only the 2 first pages,
    // so they are able to edit the company information.
    if (currentCompanyNonDeCCorp && !(location.pathname.includes("company1") || location.pathname.includes("company2")))
      navigate(ONBOARDING_NON_DE_C_CORP);
  }, [currentCompanyNonDeCCorp, location.pathname]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function handleMessage(event: MessageEvent<IframeMessageType>) {
    if (
      event &&
      (event.origin === REACT_BASE_URL || event.origin === DJANGO_BASE_URL) &&
      event.data &&
      IFRAME_MESSAGE_TYPES.includes(event.data.type)
    ) {
      if (event.data.type === NAVIGATION) {
        if (event.data.data.newTab) {
          window.open(event.data.data.url, "_blank");
        } else if (
          removeLeadingAndTrailingSlashes(location.pathname) !== removeLeadingAndTrailingSlashes(event.data.data.url) &&
          removeLeadingAndTrailingSlashes(window.location.pathname) !==
            removeLeadingAndTrailingSlashes(event.data.data.url)
        ) {
          navigate(event.data.data.url);
        }
      } else if (
        event.data.type === PAGE_LOAD &&
        event.origin === DJANGO_BASE_URL &&
        removeLeadingAndTrailingSlashes(location.pathname) !== removeLeadingAndTrailingSlashes(event.data.data.url) &&
        removeLeadingAndTrailingSlashes(window.location.pathname) !==
          removeLeadingAndTrailingSlashes(event.data.data.url)
      ) {
        navigate(event.data.data.url);
      } else if (event.data.type === SIDENAV_COLLAPSE && event.origin === DJANGO_BASE_URL) {
        toggleSidenav(event.data.data.collapseSidenav);
      }
    }
  }

  function toggleSidenav(setState: boolean) {
    setIsSidenavCollapsed(setState);
  }

  return (
    <LoggedInUserContext.Provider
      value={{
        currentLoggedInUserId,
        currentLoggedInUserName,
        currentCompanyId,
        currentCompanyName,
        currentDashboardOnboarding,
        currentCompanyDepositPaid,
        currentCompanyNonDeCCorp,
        currentCompanyShowNewRunway,
      }}
    >
      <CompanyLevelSettingsContext.Provider value={companyLevelSettings}>
        <div>
          {sessionCookie && !matchPathInArray(PATHS_TO_HIDE_SIDEBAR, location.pathname) && <Sidenav />}

          {isSidenavCollapsedPath(location.pathname) && (
            <div
              className={
                isSidenavCollapsed || !matchPathInArray(PATHS_TO_HIDE_SIDEBAR, location.pathname)
                  ? "content"
                  : "content open"
              }
            >
              {!matchPathInArray(PATHS_TO_HIDE_SIDEBAR, location.pathname) && (
                <Button
                  variant={"outline-secondary"}
                  className={isSidenavCollapsed ? "sidebar-toggle-button open" : "display-none"}
                  onClick={() => toggleSidenav(!isSidenavCollapsed)}
                  size="sm"
                >
                  <FeatherIcon icon={isSidenavCollapsed ? "chevron-right" : "chevron-left"} size="17" />
                </Button>
              )}
              <Outlet />
            </div>
          )}

          {!isSidenavCollapsedPath(location.pathname) && (
            <div
              className={`${
                matchPathInArray(PATHS_TO_HIDE_SIDEBAR, location.pathname) ? "" : "leftNavbarPadding"
              } container-fluid`}
            >
              <Outlet />
            </div>
          )}
          <Passport />
        </div>
      </CompanyLevelSettingsContext.Provider>
    </LoggedInUserContext.Provider>
  );
}
