import { FormikContextType } from "formik";
import { Col, Row } from "react-bootstrap";
import FondoFormGroup from "./FondoFormGroup";
import { FieldTypes } from "./formTypes";
import { followPath } from "./utils";

const defaultFieldTypes = {
  country: FieldTypes.COUNTRY,
  streetAddress: FieldTypes.TEXT,
  city: FieldTypes.TEXT,
  state: FieldTypes.STATE,
  postalCode: FieldTypes.TEXT,
};

const defaultFieldLabels = {
  country: "Country",
  streetAddress: "Street Address",
  city: "City",
  state: "State/Province",
  postalCode: "Postal Code",
};

type AddressFieldGroupProps = {
  formikContext: FormikContextType<any>;
  baseName?: string;
  labels?: { [key: string]: string };
  types?: { [key: string]: FieldTypes };
  defaultCountryCode?: string;
  names?: Record<keyof typeof defaultFieldTypes, string>;
  required?: boolean;
};

const AddressFieldGroup = ({
  formikContext,
  baseName,
  labels,
  types,
  defaultCountryCode,
  names = {
    streetAddress: "streetAddress",
    city: "city",
    state: "state",
    postalCode: "postalCode",
    country: "country",
  },
  required
}: AddressFieldGroupProps) => {

  const getSelectedCountry = (): string | undefined => {
    if (!baseName) {
      return formikContext.values.country;
    }
    const path = baseName.split(".");
    path.push("country");
    return followPath(formikContext.values, path);
  };

  return (
    <>
      <Row>
        <FondoFormGroup
          fieldName={`${baseName || ""}${baseName ? "." : ""}${names.streetAddress}`}
          types={types || defaultFieldTypes}
          labels={labels || defaultFieldLabels}
          formikContext={formikContext}
          required={required}
        />
      </Row>
      <Row>
        <Col>
          <FondoFormGroup
            fieldName={`${baseName || ""}${baseName ? "." : ""}${names.city}`}
            types={types || defaultFieldTypes}
            labels={labels || defaultFieldLabels}
            formikContext={formikContext}
            required={required}
          />
        </Col>
        {!defaultCountryCode && (
          <Col>
            <FondoFormGroup
              fieldName={`${baseName || ""}${baseName ? "." : ""}${names.country}`}
              types={types || { country: FieldTypes.COUNTRY }}
              labels={labels || { country: "Country" }}
              formikContext={formikContext}
              required={required}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <FondoFormGroup
            fieldName={`${baseName || ""}${baseName ? "." : ""}${names.state}`}
            types={types || defaultFieldTypes}
            labels={labels || defaultFieldLabels}
            formikContext={formikContext}
            selectedCountry={defaultCountryCode || getSelectedCountry()}
            required={required}
          />
        </Col>
        <Col>
          <FondoFormGroup
            fieldName={`${baseName || ""}${baseName ? "." : ""}postalCode`}
            types={types || defaultFieldTypes}
            labels={labels || defaultFieldLabels}
            formikContext={formikContext}
            required={required}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddressFieldGroup;
