import { useQuery } from "@tanstack/react-query";
import { PersonalDetailInput } from "./signup.type";
import { getApiClient } from "../../../utils/util.api";

export const USER_SIGNUP_QUERY_KEY = "userSignup";

export async function createSignupPersonalDetails(data: PersonalDetailInput) {
  const apiClient = await getApiClient();
  const response = await apiClient.createReactifyOnboardingSignup(null, data);
  return response.data;
}

export async function getSignupPersonalDetails() {
  const apiClient = await getApiClient();
  const response = await apiClient.retrieveReactifyOnboardingSignup();
  return response.data;
}

export const useExistingSignupPersonalDetails = () => useQuery([USER_SIGNUP_QUERY_KEY], getSignupPersonalDetails, {
    staleTime: Infinity, // Use existing data if available
  });
