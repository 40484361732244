import { useEffect } from "react";
import { matchPath, Outlet, useLoaderData, useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import FondoLayout from "../../fusion-kit/FondoLayout";
import { OnboardingForm } from "../dynamicOnboardingForm.type";
import { ONBOARDING_FORM } from "../../../constants/network-calls";
import DynamicOnboardingTimeline from "./DynamicOnboardingTimeline";
import { SESSION_COOKIE_NAME } from "../../../constants/general";
import { cookies } from "../../App";
import FondoHeader from "../FondoHeader";
import {
  CHECKOUT,
  getCurrentSectionAndPageFromSlugs,
  getNextPageAndSection,
  SIGNUP,
} from "../dynamicOnboardingForm.utils";
import { RevenueHeroScriptProvider } from "../../revenue-hero/RevenueHeroScriptProvider";

const DynamicOnboardingFormWrapper = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsStr = searchParams.toString();
  const location = useLocation();
  const onboardingFormData = useLoaderData() as OnboardingForm;

  const sessionCookie = cookies.get(SESSION_COOKIE_NAME);

  // NOTE: Anything put into the excluded paths cannot be a section slug
  const excludedPaths = [CHECKOUT, SIGNUP];

  const redirectOnboardingFormSlug = () => {
    const validSection = onboardingFormData.onboardingForm.sections.find(
      (section) => section.slug === params.sectionSlug,
    );
    if (validSection) {
      const validPage = validSection?.pages.some((page) => page.order === Number(params.pageNumber));
      if (validPage) {
        return;
      }
      navigate(`${validSection.slug}/${validSection.pages[0].order}/`, { replace: true });
      return;
    }

    // try using page after last location
    const { sectionSlug: lastSectionSlug, pageSlug: lastPageSlug } =
      onboardingFormData.onboardingForm.lastLocation || {};
    const { currentSection: lastSection, currentPage: lastPage } = getCurrentSectionAndPageFromSlugs(
      onboardingFormData.onboardingForm,
      lastSectionSlug,
      lastPageSlug,
    );
    let nextSection;
    let nextPage;
    if (lastSection && lastPage) {
      ({ nextSection, nextPage } = getNextPageAndSection(onboardingFormData.onboardingForm, lastSection, lastPage));
    }
    const defaultSectionSlug = nextSection?.slug ?? onboardingFormData.onboardingForm.sections[0]?.slug;
    const defaultPageNumber = nextPage?.order ?? onboardingFormData.onboardingForm.sections[0]?.pages[0]?.order;

    if (defaultSectionSlug && defaultPageNumber) {
      navigate(`${defaultSectionSlug}/${defaultPageNumber}/`, { replace: true });
    }
  };

  useEffect(() => {
    const isExcludedPath = excludedPaths.some((path) =>
      matchPath(`/${ONBOARDING_FORM}:onboardingSlug/${path}`, location.pathname),
    );
    if (!sessionCookie && onboardingFormData.onboardingForm.showUserSignup) {
      navigate(`/${ONBOARDING_FORM}${onboardingFormData.slug}/${SIGNUP}?${searchParamsStr}`, { replace: true });
    } else if (!isExcludedPath) {
      redirectOnboardingFormSlug();
    }
  }, [location.pathname]);

  const formContent = (
    <RevenueHeroScriptProvider>
      <DynamicOnboardingTimeline
        currentSectionSlug={params.sectionSlug || ""}
        sections={onboardingFormData.onboardingForm.sections}
        checkoutPage={{
          showCheckoutPage: !!onboardingFormData.onboardingForm.showCheckoutPage,
          checkoutPageName: onboardingFormData.onboardingForm.checkoutPageName || "Instant Quote",
        }}
        userSignup={{
          showUserSignup: !!onboardingFormData.onboardingForm.showUserSignup,
          userSignupName: onboardingFormData.onboardingForm.userSignupName || "Personal",
        }}
      />
      <Outlet context={onboardingFormData} />
    </RevenueHeroScriptProvider>
  );

  return (
    <>
      <FondoHeader />
      {onboardingFormData.onboardingForm.shouldNameDisplay ? (
        <FondoLayout title={onboardingFormData.onboardingForm.name}>{formContent}</FondoLayout>
      ) : (
        formContent
      )}
    </>
  );
};

export default DynamicOnboardingFormWrapper;
