import {
  Form, Formik,
} from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import {
  ACCELERATOR_CHOICES,
  BATCH_CHOICES,
  HOW_HEARD_CHOICES,
} from "../../../constants/general";
import {
  CompanyDashboardOnboarding,
  companyFieldToLabelMap,
  companyFieldToTypeMap,
} from "./company.type";
import {
  getCompanyDashbaordOnboarding,
  upsertCompanyDashboardOnboarding,
} from "./company.api";
import {
  DASHBOARD_ONBOARDING_CONNECTIONS,
} from "../../../constants/network-calls";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";

const Company = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Retrieve existing data for the Dashboard Onboarding Company form
  const [formValues, setFormValues] = useState<CompanyDashboardOnboarding>({});
  useQuery<CompanyDashboardOnboarding, Error>(
    ["getCompanyDashbaordOnboarding"],
    getCompanyDashbaordOnboarding,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setFormValues(data);
        }
      },
    },
  );

  // Create or update a Company
  const companyMutation = useMutation<any, AxiosError<any>, CompanyDashboardOnboarding>(
    (data) => {
      if (data.incorporationYear === "") data.incorporationYear = null;
      return upsertCompanyDashboardOnboarding(data);
    },
  );

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={Yup.object({
        legalName: Yup.string().trim().required("This field is required"),
      })}
      onSubmit={(values, { setErrors }) => {
        companyMutation.mutate(values, {
          onError: (err) => {
            const errors = err?.response?.data;
            if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
              setErrors(errors);
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["flowTrackingSteps"] });
            navigate(DASHBOARD_ONBOARDING_CONNECTIONS);
          },
        });
      }}
    >
      {(formik) => (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 mx-3">
            <h2>Company</h2>
            <p className="text-muted">Please tell us about your company</p>
            <Form>
              <div className="row my-4">
                <div className="col-12 col-md-12">
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName={"legalName"}
                    labels={companyFieldToLabelMap}
                    types={companyFieldToTypeMap}
                  />
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12 col-md-6">
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName={"incorporationYear"}
                    labels={companyFieldToLabelMap}
                    types={companyFieldToTypeMap}
                    includeSelectorEmptyValue={true}
                    mutedDescription={"Optional"}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName={"howHeard"}
                    labels={companyFieldToLabelMap}
                    types={companyFieldToTypeMap}
                    selectorOptions={HOW_HEARD_CHOICES}
                    mutedDescription={"Optional"}
                    includeSelectorEmptyValue={true}
                  />
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12 col-md-6">
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName={"accelerator"}
                    labels={companyFieldToLabelMap}
                    types={companyFieldToTypeMap}
                    selectorOptions={ACCELERATOR_CHOICES}
                    mutedDescription={"Optional"}
                    includeSelectorEmptyValue={true}
                  />
                </div>
                {formik.values.accelerator === "Y Combinator" && (
                  <div className="col-12 col-md-6">
                    <FondoFormGroup
                      formikContext={formik}
                      fieldName={"batch"}
                      labels={companyFieldToLabelMap}
                      types={companyFieldToTypeMap}
                      selectorOptions={BATCH_CHOICES}
                      mutedDescription={"Optional"}
                    />
                  </div>
                )}
              </div>
              <div className="row my-4">
                <div className="col-12 col-md-6">
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName={"deCCorp"}
                    labels={companyFieldToLabelMap}
                    types={companyFieldToTypeMap}
                    includeSelectorEmptyValue={true}
                    mutedDescription={"Optional"}
                  />
                </div>
              </div>
              <hr className="row my-5" />
              <div className="row my-4">
                <div className="d-flex flex-row col-12 col-md-12 justify-content-end ">
                  <Button className="btn btn-lg btn-primary" type="submit">
                      {companyMutation.isLoading ? <Spinner /> : <div>Next</div>}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Company;
