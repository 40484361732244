
export const REVENUE_HERO_SCRIPT_ID = "revenue-hero-script";

declare global {
  interface Window {
    RevenueHero: any;
  }
}

export const getOrLoadRevenueHeroScript = async () => {
  const existingScript = document.getElementById(REVENUE_HERO_SCRIPT_ID);
  if (existingScript) return;

  const scriptEl = document.createElement("script");

  const promise = new Promise<void>((resolve, reject) => {
    scriptEl.addEventListener("load", () => {
      resolve();
    });

    scriptEl.addEventListener("error", (e) => {
      reject(e);
    });
  });

  scriptEl.id = REVENUE_HERO_SCRIPT_ID;
  scriptEl.async = true;
  scriptEl.src = "https://app.revenuehero.io/scheduler.min.js";
  document.body.appendChild(scriptEl);
  return promise;
};
export const removeRevenueHeroScript = () => {
  const existingScript = document.getElementById(REVENUE_HERO_SCRIPT_ID);
  if (existingScript) {
    existingScript.remove();
  }
};


