const DynamicOnboardingFormErrorPage = () => (
  <div
    className="d-flex align-items-center flex-column justify-content-center"
    style={{ height: "100vh" }}>
    <h1 className="my-3">
      Whoops!
    </h1>
    <p className="mb-2 text-muted">
      Looks like the form you're looking for doesn't exist!
    </p>
  </div>
);

export default DynamicOnboardingFormErrorPage;
