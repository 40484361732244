import { ActionItemsService } from "./actionItems.type";

export const calculatePercentageOfActionItemServiceCompletion = (actionItemService: ActionItemsService) => {
  const percentage = (1 - actionItemService.numberOfPendingActionItems / actionItemService.numberOfActionItems)
    * 100 || 0;
  return Math.round(percentage);
};

export const isActionItemNotStarted = (
  actionItemService: ActionItemsService,
) => actionItemService?.numberOfPendingActionItems === actionItemService?.numberOfActionItems;

export const isActionItemCompleted = (
  actionItemService: ActionItemsService,
) => actionItemService?.numberOfPendingActionItems === 0;

export const mapFormTypeToUrl: { [key: string]: any } = {
  "Foreign Subsidiary Tax Year": "foreign_subsidiary",
  "Corp Tax Onboarding": "corp_tax_onboarding",
  "DE Franchise Tax Onboarding": "delaware_franchise_tax",
  "Tax Credit Onboarding": "questionnaire/f/r_and_d",
  1099: "form_1099",
};

export const getUrlForFormActionItem = (formType: string, formTypeTaxYear: number) => {
  // Returns the url for the different forms.

  let url = mapFormTypeToUrl[formType];
  url += `/${formTypeTaxYear}`;
  return `/${url}/`;
};
