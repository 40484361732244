import { FormikContextType } from "formik";
import {
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "../DelawareFranchiseTax.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";

type AddressInfoSectionProps = {
  formikContext: FormikContextType<any>;
  taxYear: string | number;
};

const AddressInfoSection = ({ formikContext, taxYear }: AddressInfoSectionProps) => (
  <>
    <div className="mt-5">
      <h2 className="m-0">Address Information</h2>
    </div>
    <hr />
    <AddressFieldGroup formikContext={formikContext} baseName="addressInfo" defaultCountryCode="US" />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName={"addressInfo.phone"}
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
  </>
);

export default AddressInfoSection;
