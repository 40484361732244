import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { createSignupPersonalDetails, USER_SIGNUP_QUERY_KEY } from "./signup.api";
import { PersonalDetailError, PersonalDetailInput, UserSignupData } from "./signup.type";
import { OnboardingForm } from "../../dynamic-onboarding-forms/dynamicOnboardingForm.type";
import { ONBOARDING_FORM } from "../../../constants/network-calls";
import { TalkWithAnExpert } from "../../dynamic-onboarding-forms/TalkWithAnExpert";

type SignupKey = keyof Omit<PersonalDetailInput, "dashboardOnboarding">;

const SEARCH_PARAMS_KEYS: SignupKey[] = ["firstName", "lastName", "email", "title", "companyName"];
const FORM_KEYS: SignupKey[] = [...SEARCH_PARAMS_KEYS, "password"];

export default function Signup() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const onboardingFormData = useOutletContext<OnboardingForm | undefined>();
  const signupMutation = useMutation<UserSignupData, AxiosError<PersonalDetailError>, PersonalDetailInput>(
    createSignupPersonalDetails,
    {
      onSuccess: (data) => {
        queryClient.setQueryData([USER_SIGNUP_QUERY_KEY], data);
      },
    },
  );

  const goToForm = (formData: OnboardingForm) => {
    const onboardingFormSlug = formData.slug;
    const firstSection = formData.onboardingForm.sections[0];
    const firstPage = firstSection?.pages[0];
    navigate(`/${ONBOARDING_FORM}${onboardingFormSlug}/${firstSection?.slug}/${firstPage?.order}/`);
  };

  const getInitialValues = () =>
    Object.fromEntries(
      FORM_KEYS.map((key) => [key, (SEARCH_PARAMS_KEYS.includes(key) && searchParams.get(key)) || ""]),
    ) as Record<SignupKey, string>;

  return (
    <div>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={Yup.object({
          firstName: Yup.string().required("This field is required"),
          lastName: Yup.string().required("This field is required"),
          companyName: Yup.string().required("This field is required"),
          email: Yup.string().email("Invalid email address").required("This field is required"),
          title: Yup.string().required("This field is required"),
          password: Yup.string().required("This field is required"),
        })}
        onSubmit={(values, { setErrors }) => {
          signupMutation.mutate(values, {
            onError: (err) => {
              const errors = err?.response?.data;
              if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                setErrors(errors as Record<keyof PersonalDetailInput, string | undefined>);
              }
            },
            onSuccess: () => (onboardingFormData ? goToForm(onboardingFormData) : navigate("/onboarding/company1")),
          });
        }}
        validateOnMount
      >
        {(formikContext) => (
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 card">
              <Form className="card-body mt-5 mx-3">
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-6">
                    <label htmlFor="firstName">First Name</label>
                    <Field name="firstName" type="text" className={"form-control"} placeholder={"First Name"} />
                    <ErrorMessage component="div" className="text-danger" name="firstName" />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="lastName">Last Name</label>
                    <Field name="lastName" type="text" className={"form-control"} placeholder={"Last Name"} />
                    <ErrorMessage component="div" className="text-danger mt-1" name="lastName" />
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="title">Title</label>
                    <Field name="title" type="text" className={"form-control"} placeholder={"CEO"} />
                    <ErrorMessage component="div" className="text-danger" name="title" />
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="companyName">Company Name</label>
                    <Field name="companyName" type="text" className={"form-control"} placeholder={"Fondo"} />
                    <ErrorMessage component="div" className="text-danger" name="companyName" />
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="email">Email Address</label>
                    <Field name="email" type="text" className={"form-control"} placeholder={"name@address.com"} />
                    <ErrorMessage component="div" className="text-danger" name="email" />
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="password">Password</label>
                    <Field name="password" type="password" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="password" />
                  </div>
                </div>
                <hr className="row my-5" />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end ">
                    <button disabled={!formikContext.isValid} className="btn btn-lg btn-primary" type="submit">
                      {signupMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                    </button>
                  </div>
                </div>
              </Form>
              <TalkWithAnExpert
                className="ms-4 mb-4 me-4"
                signupData={formikContext.values}
                emailInvalid={!!formikContext.errors.email}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
