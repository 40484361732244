import { PassportField } from "./passport.type";
import { getApiClient } from "../../utils/util.api";

export async function getPassport(): Promise<PassportField[]> {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassport();
  return response.data;
}

export async function postPassportField(passportField: PassportField): Promise<PassportField> {
  const apiClient = await getApiClient();
  const response = await apiClient.createReactifyPassport(null, passportField);
  return response.data;
}

export async function patchPassportField(passportField: PassportField): Promise<PassportField> {
  const apiClient = await getApiClient();
  const response = await apiClient.partialUpdateReactifyPassport(passportField.fieldName, passportField);
  return response.data;
}

export async function deletePassportField(passportField: PassportField): Promise<void> {
  const apiClient = await getApiClient();
  await apiClient.destroyReactifyPassport(passportField.fieldName);
}

export async function reorderPassportFields(fieldName: string, newIndex: number): Promise<void> {
  const apiClient = await getApiClient();
  await apiClient.createReactifyPassportReorderFields(null, { fieldName, newIndex });
}
