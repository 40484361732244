import { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import ProductDetailRow from "./ProductDetailRow";
import { OrderAPIData, ProductPricingAPIData, ProductPricingCategory } from "../product/product.type";
import { ProductType, mapProductTypeToString } from "./products.type";
import {
  ANNUAL,
  MONTHLY,
  QUARTERLY,
} from "./constants";

type ProductsGroupedByTypeCardProps = {
  productsGroupedByType: ProductPricingCategory,
  openOrder?: OrderAPIData,
  handleSelectProduct: (productUuid: string) => void;
  handleRemoveProduct: (lineItemUuid: string) => void;
}

const mapBillingFrequencyToTabName = new Map<string, string>([
  ["QUARTERLY", QUARTERLY],
  ["MONTHLY", MONTHLY],
  ["YEARLY", ANNUAL],
  ["ONE_TIME", ANNUAL],
]);

const ProductsGroupedByTypeCard = ({
  productsGroupedByType, openOrder, handleSelectProduct, handleRemoveProduct,
}: ProductsGroupedByTypeCardProps) => {
  // products are ordered descending by taxYear - reverse for chronological order
  const tabsOptions = productsGroupedByType.category === ProductType.TAX
    ? Array.from(new Set(productsGroupedByType.products.map((product) => String(product.taxYear)))).reverse()
    : [QUARTERLY, MONTHLY, ANNUAL];

  const tabToProducts = new Map<string, ProductPricingAPIData[]>();

  productsGroupedByType.products.forEach((product: ProductPricingAPIData) => {
    const key = productsGroupedByType.category === ProductType.TAX
      ? String(product.taxYear)
      : mapBillingFrequencyToTabName.get(product.billingFrequency);
    if (!key) return;
    if (tabToProducts.has(key)) {
      tabToProducts.get(key)?.push(product);
    } else {
      tabToProducts.set(key, [product]);
    }
  });

  const getInitialCurrentTab = () => {
    if (productsGroupedByType.category === ProductType.TAX) return tabsOptions.at(-1) || "";
    if (tabToProducts.has(ANNUAL)) return ANNUAL;
    if (tabToProducts.has(MONTHLY)) return MONTHLY;
    if (tabToProducts.has(QUARTERLY)) return QUARTERLY;
    return "";
  };

  const [currentTab, setCurrentTab] = useState(getInitialCurrentTab());

  const tabIsActive = (tabName: string) => (
    `${productsGroupedByType.category}${tabName}` === `${productsGroupedByType.category}${currentTab}`
  );

  const multipleTabs = () => tabToProducts.size > 1;

  const getListOfProducts = (selectedTab: string) => {
    if (tabToProducts.has(selectedTab)) {
      return tabToProducts.get(selectedTab) || [];
    }
    return productsGroupedByType.products;
  };

  const activeProductInTab = (tabName: string) => {
    if (!openOrder) return false;
    return getListOfProducts(tabName).some((product) => {
      const selected = openOrder.lineItems.some((lineItem) => lineItem.product.uuid === product.uuid);
      return selected;
    });
  };

  return (
    <Card style={{ minWidth: "529px" }}>
      <Card.Header className="p-0 px-3">
        <span className="text-start fw-bold">
          {mapProductTypeToString[productsGroupedByType.category]}
        </span>
        {multipleTabs()
          && (
            <Nav className="navbar navbar-expand-lg navbar-light p-0 align-self-end">
              {tabsOptions.map(
                (tabName: string, idx: number) => (
                  <Nav.Item
                    key={`${tabName}-${idx}`}
                    onClick={() => setCurrentTab(tabName)}
                    role="button"
                  >
                    <Nav.Link
                      active={tabIsActive(tabName)}
                      className={`${tabIsActive(tabName) ? "text-dark" : "text-muted"}`}
                    >
                      <span>{tabName}</span>
                      {activeProductInTab(tabName)
                        && (
                          <span className="px-1">
                            <FeatherIcon className="mb-1" icon="circle" size="10" fill="#2A69ED" strokeWidth={0} />
                          </span>
                        )
                      }
                    </Nav.Link>
                  </Nav.Item>
                ),
              )}
            </Nav>
          )
        }
      </Card.Header>
      <Card.Body>
        {getListOfProducts(currentTab).map((product: ProductPricingAPIData, idx: number) => (
          <>
            <ProductDetailRow
              key={product.uuid}
              product={product}
              openOrder={openOrder}
              handleSelectProduct={handleSelectProduct}
              handleRemoveProduct={handleRemoveProduct}
            />
            {idx < getListOfProducts(currentTab).length - 1
              && <hr style={{ borderBlockColor: "#EFF2F7", marginBottom: "10px", marginTop: "0px" }}></hr>
            }
          </>
        ))
        }
      </Card.Body>
    </Card>
  );
};

export default ProductsGroupedByTypeCard;
