import { useQuery } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
import { useContext, useState } from "react";
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import fondoLogo from "../../assets/img/fondo-icon.png";
import qboLogo from "../../assets/img/qbo-icon.svg";
import { AccountingService } from "../../common/type";
import {
  FINANCE_DASHBOARD_BALANCE_SHEET,
  FINANCE_DASHBOARD_P_AND_L,
  FINANCE_DASHBOARD_RUNWAY,
  FINANCE_DASHBOARD_TAX_PLAN,
  FINANCE_DASHBOARD_TRANSACTIONS,
} from "../../constants/network-calls";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import {
  getCustomerServicesRequired,
  getPurchasedAccountingServices,
} from "../customer-order/customerOrder.api";
import AdditionalFondoServicesCard from "../dashboard-onboarding/sales/AdditionalFondoServicesCard";
import FondoLayout, { FondoLayoutProps } from "../fusion-kit/FondoLayout";
import { getSourceLinkedAccounts } from "../integrations/integrations.api";
import FeatureInBetaBanner from "./FeatureInBetaBanner";
import { getBalanceAvailableMonths, getTransactions } from "./FinanceDashboard.api";
import { FinanceDashboardContext } from "./FinanceDashboardContext";
import PreliminaryDataBanner from "./PreliminaryDataBanner";
import Runway from "./Runway";
import TimeRangeIntervalSelector from "./TimeRangeIntervalSelector";
import BalanceSheet from "./balance-sheet/BalanceSheet";
import { mapMonthDateToMonth, mapMonthToMonthDate, mapQuarterToMonthDate } from "./constants";
import { DateRangeIntervals, TimeRange, Transaction } from "./financeDashboard.type";
import ProfitAndLoss from "./profit-and-loss/ProfitAndLoss";
import TaxPlan from "./tax-plan/TaxPlan";
import { getInitialTimeRange } from "./utils";
import Transactions from "./verify-transactions/Transactions";

const FinanceDashboard = () => {
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);

  const [selectedTaxYear, setSelectedTaxYear] = useState<number>(2024);
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(getInitialTimeRange());
  const [collapseBannerContent, setCollapseBannerContent] = useState<boolean>(false);

  const constructSelectedMonthText = (): string => {
    if (selectedTimeRange.interval === "year") {
      return `${selectedTimeRange.year}-12`;
    }
    if (selectedTimeRange.interval === "quarter" && selectedTimeRange.timePeriod) {
      return `${selectedTimeRange.year}-${mapQuarterToMonthDate[selectedTimeRange.timePeriod]}`;
    }
    if (selectedTimeRange.timePeriod) {
      return `${selectedTimeRange.year}-${mapMonthToMonthDate[selectedTimeRange.timePeriod]}`;
    }
    return "";
  };

  const { currentCompanyId } = useContext(LoggedInUserContext);

  const { data: balanceAvailableMonths } = useQuery<DateRangeIntervals, Error>(
    ["balanceAvailableMonths"],
    () => getBalanceAvailableMonths(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (dateRange) => {
        if (dateRange.months.length) {
          const [year, month] = dateRange.months[0].split("-");
          setSelectedTimeRange({
            interval: "month",
            year,
            timePeriod: mapMonthDateToMonth[month],
          });
        }
      },
    },
  );

  const [useQboSource, setUseQboSource] = useState(false);
  const { data: qboConnections } = useQuery<any, Error>(
    ["linkedAccountData", "QBO"],
    () => getSourceLinkedAccounts("QBO"),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  // old customer services required
  const { data: customerServicesRequired } = useQuery<any, Error>(
    ["customerServicesRequired", currentCompanyId],
    () => getCustomerServicesRequired(currentCompanyId || ""),
    { enabled: !!currentCompanyId },
  );

  const hasBookkeepingFromCustomerServicesRequired = customerServicesRequired
    && customerServicesRequired.length > 0
    && customerServicesRequired[0].servicesRequired.some(
      (service: string) => service.includes("Bookkeeping"),
    );

  // new customer accounting services required
  const { data: purchasedAccountingServices } = useQuery<AccountingService[], Error>(
    ["purchasedAccountingServices", currentCompanyId],
    getPurchasedAccountingServices,
    { enabled: !!currentCompanyId },
  );

  const hasBookkeepingFromPurchasedAccountingServices = !!purchasedAccountingServices?.some(
    (accService: AccountingService) => !accService.isTaxService
      && accService.name.toLowerCase().includes("bookkeeping"),
  );

  const location = useLocation();
  const financeDashboardTabs: any[] = [];
  const actionItemTabs: any[] = [];

  if (companyLevelSettings.showFinanceDashboardRunwayPage) {
    financeDashboardTabs.push({
      name: "Runway",
      url: FINANCE_DASHBOARD_RUNWAY,
    });
  }
  if (companyLevelSettings.showFinanceDashboardPNLPage) {
    financeDashboardTabs.push({
      name: "Profit & Loss",
      url: FINANCE_DASHBOARD_P_AND_L,
    });
  }
  if (companyLevelSettings.showFinanceDashboardBalanceSheetPage) {
    financeDashboardTabs.push({
      name: "Balance Sheet",
      url: FINANCE_DASHBOARD_BALANCE_SHEET,
    });
  }
  if (companyLevelSettings.showFinanceDashboardTaxPlanPage) {
    financeDashboardTabs.push({
      name: "TaxPlan",
      url: FINANCE_DASHBOARD_TAX_PLAN,
    });
  }

  if (companyLevelSettings.showFinanceDashboardTransactionsPage) {
    actionItemTabs.push({
      name: "Transactions",
      url: FINANCE_DASHBOARD_TRANSACTIONS,
    });
  }

  const getFondoLayoutTitleName = (path: string) => {
    let title = financeDashboardTabs.find((tab) => tab.url === path)?.name;
    if (!title) {
      title = actionItemTabs.find((tab) => tab.url === path)?.name;
    }
    return title;
  };

  const { data: transactionsToVerify } = useQuery<Transaction[], Error>(
    ["transactionsToVerify"],
    () => getTransactions(),
  );

  const hasTransactionsToVerify = (transactionsToVerify && transactionsToVerify.length > 0) || false;

  const currentLocationIsBalanceSheetOrPnL = () => {
    if (
      location.pathname === FINANCE_DASHBOARD_P_AND_L
      || location.pathname === FINANCE_DASHBOARD_BALANCE_SHEET
    ) {
      return true;
    }
    return false;
  };

  const currentLocationIsTaxPlan = () => (
    location.pathname === FINANCE_DASHBOARD_TAX_PLAN
  );

  const getPageLayoutBadge = () => {
    if (location.pathname === FINANCE_DASHBOARD_RUNWAY) {
      return {
        badgeColor: "primary" as FondoLayoutProps["badgeColor"],
        badgeText: "Beta",
      };
    }
    return {
      badgeColor: "primary-soft" as FondoLayoutProps["badgeColor"],
      badgeText: "Preliminary",
    };
  };

  const displayDateSelectorAndSource = location.pathname !== FINANCE_DASHBOARD_TRANSACTIONS;

  return (
    <FinanceDashboardContext.Provider
      value={{
        useQboSource,
        setUseQboSource,
      }}
    >
      <FondoLayout
        pretitle="Finance Dashboard"
        title={getFondoLayoutTitleName(location.pathname)}
        badge={true}
        badgeColor={getPageLayoutBadge().badgeColor}
        badgeText={getPageLayoutBadge().badgeText}
        tabs={financeDashboardTabs}
        actionTabItems={actionItemTabs}
      >
        {currentLocationIsBalanceSheetOrPnL() && (
          <PreliminaryDataBanner
            showUpgradeYourAccountText={
              !(hasBookkeepingFromCustomerServicesRequired || hasBookkeepingFromPurchasedAccountingServices)
            }
            collapseBannerContent={collapseBannerContent}
            setCollapseBannerContent={setCollapseBannerContent}
          />
        )}
        {location.pathname === FINANCE_DASHBOARD_RUNWAY && (
          <FeatureInBetaBanner />
        )}
        {balanceAvailableMonths && displayDateSelectorAndSource && (
          <div className="my-3">
            {currentLocationIsTaxPlan() ? (
              <div className="d-flex align-items-center">
                <p className="mb-0 me-2 fs-4">Tax Year: </p>
                <InputGroup size="sm" style={{ width: "auto", height: "30px" }}>
                  <InputGroup.Text className="pe-0">
                    <FeatherIcon
                      icon="calendar"
                      size="1.3em"
                      className="text-secondary"
                    />
                  </InputGroup.Text>
                  <Form.Select
                    className="border-start-0 no-outline-in-focus-input"
                    value={selectedTaxYear}
                    onChange={(e) => setSelectedTaxYear(Number(e.target.value))}
                  >
                    <option
                      key={"2024"}
                      value={2024}
                    >
                      2024
                    </option>
                    <option
                      key={"2023"}
                      value={2023}
                    >
                      2023
                    </option>
                  </Form.Select>
                </InputGroup>
              </div>
            ) : (
              <Row>
                <Col>
                  <TimeRangeIntervalSelector
                    className="w-50"
                    balanceAvailableIntervals={balanceAvailableMonths}
                    handleApplyInterval={setSelectedTimeRange}
                    currentTimeRange={selectedTimeRange}
                  />
                </Col>
                <Col>
                  {qboConnections?.length > 0 && location.pathname !== FINANCE_DASHBOARD_RUNWAY &&  (
                    <div className="d-flex align-items-center float-end">
                      <p className="mb-0 me-2 fs-4">Source</p>
                      <DropdownButton
                        variant="white"
                        size="sm"
                        title={
                          <>
                            <img
                              src={useQboSource ? qboLogo : fondoLogo}
                              width="15px"
                              className="text-secondary me-2"
                            />
                            <span className="me-3 align-middle">
                              {useQboSource ? "QBO" : "Fondo"}
                            </span>
                          </>
                        }
                      >
                        <Dropdown.Item onClick={() => setUseQboSource(false)}>Fondo</Dropdown.Item>
                        <Dropdown.Item onClick={() => setUseQboSource(true)}>QBO</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        )}
        {location.pathname === FINANCE_DASHBOARD_RUNWAY
          && <Runway
            selectedMonth={constructSelectedMonthText()}
            hasTransactionsToVerify={hasTransactionsToVerify}
            interval={selectedTimeRange.interval}
          />
        }
        {location.pathname === FINANCE_DASHBOARD_P_AND_L
          && <ProfitAndLoss
            selectedMonth={constructSelectedMonthText()}
            selectedTimeRange={selectedTimeRange}
            hasTransactionsToVerify={hasTransactionsToVerify}
          />
        }
        {location.pathname === FINANCE_DASHBOARD_BALANCE_SHEET
          && <BalanceSheet
            selectedMonth={constructSelectedMonthText()}
            hasTransactionsToVerify={hasTransactionsToVerify}
            interval={selectedTimeRange.interval}
          />}
        {location.pathname === FINANCE_DASHBOARD_TAX_PLAN
          && <TaxPlan selectedTaxYear={selectedTaxYear}/>}
        {location.pathname === FINANCE_DASHBOARD_TRANSACTIONS
          && <Transactions />
        }

        <div className="my-4">
          <AdditionalFondoServicesCard />
        </div>
      </FondoLayout>
    </FinanceDashboardContext.Provider>
  );
};

export default FinanceDashboard;
