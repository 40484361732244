import { useNavigate, useOutletContext, useParams } from "react-router";
import { OnboardingForm } from "../dynamicOnboardingForm.type";
import {
  getNextPageAndSection,
  getCurrentSectionAndPageFromSlugs,
  getPreviousPageAndSection,
  CHECKOUT,
} from "../dynamicOnboardingForm.utils";
import DynamicOnboardingFormPage from "./DynamicOnboardingFormPage";

const DynamicOnboardingForm = () => {
  const onboardingFormData = useOutletContext<OnboardingForm>();
  const params = useParams();
  const navigate = useNavigate();

  const currentSectionSlug = params.sectionSlug;
  const currentPageSlug = params.pageNumber;
  const { currentSection, currentPage } = getCurrentSectionAndPageFromSlugs(
    onboardingFormData.onboardingForm,
    currentSectionSlug,
    currentPageSlug,
  );

  if (!currentSectionSlug || !currentPageSlug || !currentSection || !currentPage) {
    return <></>;
  }

  // I was confused why this was working, as I thought any first or last page of the section would return true.
  // However, it turns the comparision is done between the object references, not the keys/values matching.
  const firstPage = onboardingFormData.onboardingForm.sections[0].pages[0] === currentPage;
  const lastPage = onboardingFormData.onboardingForm.sections.at(-1)?.pages.at(-1) === currentPage;
  const showCheckout = onboardingFormData.onboardingForm.showCheckoutPage;

  const handleNextPage = () => {
    const { nextPage, nextSection } = getNextPageAndSection(
      onboardingFormData.onboardingForm,
      currentSection,
      currentPage,
    );
    navigate(`../${nextSection?.slug}/${nextPage?.order}/`);
  };

  const handlePreviousPage = () => {
    const { previousPage, previousSection } = getPreviousPageAndSection(
      onboardingFormData.onboardingForm,
      currentSection,
      currentPage,
    );
    navigate(`../${previousSection?.slug}/${previousPage?.order}/`);
  };

  return (
    <DynamicOnboardingFormPage
      pageUuid={currentPage.uuid}
      firstPage={firstPage}
      lastPage={lastPage}
      showCheckout={!!showCheckout}
      handlePreviousPage={handlePreviousPage}
      handleNextPage={handleNextPage}
      goToCheckout={() => navigate(`../${CHECKOUT}`)}
      goToDashboard={() => navigate("/")}
    />
  );
};

export default DynamicOnboardingForm;
