import { ErrorMessage, Formik } from "formik";
import Cleave from "cleave.js/react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { OfficerFormFields, officerFieldToLabelMap, officerFieldToTypeMap } from "../corporateTax.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";

type AddOfficerModalProps = {
  show: boolean;
  handleClose: () => void;
  officer: OfficerFormFields;
  header: string;
  handleSave: (officer: OfficerFormFields) => void;
};

export default function AddOfficerModal(props: AddOfficerModalProps) {
  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={props.officer}
            enableReinitialize={true}
            validationSchema={Yup.object({})}
            onSubmit={(values) => {
              props.handleSave(values);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="firstName"
                  labels={officerFieldToLabelMap}
                  types={officerFieldToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="lastName"
                  labels={officerFieldToLabelMap}
                  types={officerFieldToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="title"
                  labels={officerFieldToLabelMap}
                  types={officerFieldToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="signingOfficer"
                  labels={officerFieldToLabelMap}
                  types={officerFieldToTypeMap}
                />
                <AddressFieldGroup defaultCountryCode="US" formikContext={formik} />
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="taxIdEnc">Tax ID</label>
                    <Cleave
                      {...formik.getFieldProps("taxIdEnc")}
                      options={{
                        delimiter: "-",
                        blocks: [3, 2, 4],
                      }}
                      placeholder="123-45-6789"
                      className="form-control"
                    />
                    <ErrorMessage component="div" className="text-danger" name="taxIdEnd" />
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit">
                      <div>Save</div>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
