import { FieldTypes } from "../components/forms/formik-components/formTypes";
import { DataPullerInfo, DataPullerTypeEnum, DataPullerTypeToFormikFieldTypeMap } from "../types/data-pullers.type";

export const PHYSICAL_ADDRESS_KEY = "physicalAddress";

export const REGION_KEY = "region";
export const COUNTRY_KEY = "country";
export const MAX_DEPTH = 5;

export const DATA_PULLER_ADDRESS_NAMES = {
  streetAddress: "address1",
  city: "city",
  state: "region",
  postalCode: "postalCode",
  country: "country",
};

export const getFormFieldNames = (dataInfo: DataPullerInfo, baseNamesOnly = false, keyNamePrefix = ""): string[] => {
  const baseKeyName = `${keyNamePrefix}${dataInfo.keyName}`;
  if (dataInfo.dataType === DataPullerTypeEnum.OBJECT) {
    return (dataInfo.objectSchema || [])
      .map((objData) => getFormFieldNames(objData, baseNamesOnly, baseNamesOnly ? "" : `${baseKeyName}.`))
      .flat(MAX_DEPTH);
  }
  return [baseKeyName];
};

export const getFormFieldTypes = (dataInfos: DataPullerInfo[]): Record<string, FieldTypes> =>
  dataInfos.reduce((acc, dataInfo) => {
    if (dataInfo.dataType === DataPullerTypeEnum.OBJECT) {
      // this assumes there are no base key name collisions (FondoFormGroup assumes no collisions)
      return { ...acc, ...getFormFieldTypes(dataInfo.objectSchema || []) };
    }
    let type = DataPullerTypeToFormikFieldTypeMap[dataInfo.dataType];
    if (dataInfo.keyName === REGION_KEY) {
      type = FieldTypes.STATE;
    } else if (dataInfo.keyName === COUNTRY_KEY) {
      type = FieldTypes.COUNTRY;
    }
    acc[dataInfo.keyName] = type;
    return acc;
  }, {} as Record<string, FieldTypes>);

export const getFormFieldLabels = (dataInfos: DataPullerInfo[]): Record<string, string> =>
  dataInfos.reduce((acc, dataInfo) => {
    if (dataInfo.dataType === DataPullerTypeEnum.OBJECT) {
      return { ...acc, ...getFormFieldLabels(dataInfo.objectSchema || []) };
      // this assumes there are no base key name collisions (FondoFormGroup assumes no collisions)
    }
    acc[dataInfo.keyName] = dataInfo.displayName;
    return acc;
  }, {} as Record<string, string>);
