import { createContext, useEffect, useState } from "react";
import { getOrLoadRevenueHeroScript, removeRevenueHeroScript } from "./revenue-hero.utils";

export const RevenueHeroScriptContext = createContext(false);

export const RevenueHeroScriptProvider = ({ children }: { children: React.ReactNode[] | React.ReactNode }) => {
  const [revenueHeroScriptLoaded, setRevenueHeroScriptLoaded] = useState(false);
  useEffect(() => {
    getOrLoadRevenueHeroScript()
      .then(() => {
        setRevenueHeroScriptLoaded(true);
      })
      .catch(() => {
        setRevenueHeroScriptLoaded(false);
      });
    return removeRevenueHeroScript;
  }, []);
  return (
    <RevenueHeroScriptContext.Provider value={revenueHeroScriptLoaded}>{children}</RevenueHeroScriptContext.Provider>
  );
};
