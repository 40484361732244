import { Navigate, useLocation } from "react-router-dom";
import { DJANGO_BASE_URL } from "../../constants/config";
import { RND, YC_ONBOARDING } from "../../constants/network-calls";
import { removeLeadingAndTrailingSlashes } from "../../utils/common.util";

const extractYearFromPath = (pathname: string) => {
  const match = pathname.match(/r_and_d\/(\d{4})/);  // Match 'r_and_d' followed by a year (4 digits)
  return match ? parseInt(match[1], 10) : null;
};

export default function IframeWrapper() {
  const location = useLocation();

  if (removeLeadingAndTrailingSlashes(location.pathname) === removeLeadingAndTrailingSlashes(YC_ONBOARDING)) {
    return <Navigate to="/onboarding" replace={true} />;
  }

  // the pathname used for the app is r_and_d/2021 or r_and_d/2022
  // the pathname used to get the forms from django is r_and_d or r_and_d_2022
  // this condition transforms the pathname visible on the page into the one that is used in Django to get forms
  if (location.pathname.includes("r_and_d")) {
    const taxYear = extractYearFromPath(location.pathname);

    if (taxYear === 2021) {
      return <iframe key={`${RND}r_and_d`} src={`${DJANGO_BASE_URL}/questionnaire/f/r_and_d`} />;
    }

    if (taxYear === 2022) {
      return <iframe key={`${RND}r_and_d_2022`} src={`${DJANGO_BASE_URL}/questionnaire/f/r_and_d_2022`} />;
    }
  }

  return <iframe key={encodeURIComponent(location.pathname)} src={DJANGO_BASE_URL + location.pathname}></iframe>;
}
