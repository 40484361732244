// Because some of these objects use a leading zero, it's necessary to encapsulate them in quotes
// However, due to Typescript's rules about wrapping properties in quotes it will throw an error if you
// attempt to wrap 10, 11, or 12 in quotes.
export const mapMonthDateToQuarter: {[key: string]: string} = {
  "03": "Q1",
  "06": "Q2",
  "09": "Q3",
  12: "Q4",
};

export const mapQuarterToMonthDate: { [key: string]: string } = {
  Q1: "03",
  Q2: "06",
  Q3: "09",
  Q4: "12",
};

export const mapMonthDateToMonth: { [key: string]: string } = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const mapMonthToMonthDate: { [key: string]: string } = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const CURRENT_YEAR = new Date().getFullYear()
export const CURRENT_MONTH =new Date().getMonth()
export const isJanuary = CURRENT_MONTH === 0
